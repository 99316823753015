import {
    Input,
    Button,
    Table,
    Typography,
    Space,
    Image,
    Modal,
    Popconfirm, Popover, Spin, Select, Checkbox, Tooltip, DatePicker, message
} from "antd";
import {useEffect, useState} from "react";
import {Card} from "@/repo/model/card";
import Cell from "@/component/table/cell";
import UserCell from "@/component/table/user-cell";
import {InfoLine, Label} from "@/component/table";
import {SearchOutlined, RestOutlined, WarningOutlined, DownloadOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import CardInput from "./input";
import styles from "./index.module.css";
import CommonTable from '@/component/table/common-table';
import {useUserSessionContext} from "@/repo/context";
import {PageList, RequestPageInfo} from "@/repo/model/common";
import ExportButton from "@/component/button/export-button";
import ExportButtonBySearch from "@/component/button/export-button-bySearch";
import {responseTransfer} from "@/repo/response/transfer";
import ImportButton from "@/component/button/import-button";
import {ResponseData} from "@/repo/response/common";
import Recycle from "@/view/card/recycle";
import {Order} from "@/repo/model/order";
import Repository from "@/repo/repository";
import {UserPhone} from "@/repo/model/user";

export default () => {
    const [editCard, setEditCard] = useState<Partial<Card>>();
    const [previewImage, setPreviewImage] = useState<string>();
    const {repo, hasPermission, userSession} = useUserSessionContext();
    const [loading, setLoading] = useState(false);
    const [requestPage, setRequestPage] = useState<RequestPageInfo>({offset: 0});
    const [datasource, setDatasource] = useState<PageList<Card>>();
    const [search, setSearch] = useState<{
        createUserName?: string
        createUserPhone?: string,
        userName?: string,
        userPhone?: string,
        yearCardOrderNo?: string,
        stokeWarningStatus?: string,
        filterEmptyCard?: boolean
    }>({
        filterEmptyCard: true,
    });
    const [openRecycle, setOpenRecycle] = useState(false);

    useEffect(() => {
        if (repo) {
            setLoading(true);
            repo.listCard({
                page: requestPage,
                fillCurrency: true,
                fillItemCount: true,
                fillAgentUser: true,
                fillUser: true,
                ...search
            })
                .then(setDatasource)
                .finally(() => setLoading(false));
        }
    }, [repo, requestPage]);



    const [isRemindModalVisible, setIsRemindModalVisible] = useState(false);
    const [reminderTime, setReminderTime] = useState("");
    const [reminderContent, setReminderContent] = useState("");
    const [yearCardId, setYearCardId] = useState("");


    const handleReminderSubmit = () => {
        // 获取提醒时间和提醒内容的值
        const time = reminderTime;
        const content = reminderContent;
        const userYearCardId = yearCardId;

        // 检查提醒内容是否为空
        if (!content) {
            // 如果提醒内容为空，触发提示
            message.error("请输入提醒内容");
            return; // 阻止继续执行
        }
        // 检查提醒时间是否为空
        if (!time) {
            // 如果提醒时间为空，触发提示
            message.error("请选择提醒时间");
            return; // 阻止继续执行
        }

        if (repo) {
            repo.addRemindTime(time,content,userYearCardId)
        }

        // 清空输入框的值
        setReminderTime("");
        setReminderContent("");

        // 关闭弹出窗口
        setIsRemindModalVisible(false);
    };


    return <>
        <CommonTable
            onPageChange={(page, pageSize) => setRequestPage({
                ...requestPage,
                offset: (page - 1) * pageSize,
                size: pageSize
            })}
            leftAction={
                hasPermission(['3', '4'])
                    ? <Button type='primary' onClick={() => setEditCard({})}>新年卡</Button>
                    : null}
            rightAction={<>
                <Input placeholder='年卡订单号'
                       allowClear
                       value={search?.yearCardOrderNo}
                       onChange={e => setSearch(p => ({...p, yearCardOrderNo: e.target.value}))}/>
                <Input placeholder='所属用户手机号'
                       inputMode="tel"
                       allowClear
                       value={search?.userPhone}
                       onChange={e => setSearch(p => ({...p, userPhone: e.target.value}))}/>
                <Input placeholder='所属用户名'
                       allowClear
                       value={search?.userName}
                       onChange={e => setSearch(p => ({...p, userName: e.target.value}))}/>
                <Input placeholder='开卡人手机号'
                       allowClear
                       inputMode="tel"
                       value={search?.createUserPhone}
                       onChange={e => setSearch(p => ({...p, createUserPhone: e.target.value}))}/>
                <Input placeholder='开卡人'
                       allowClear
                       value={search?.createUserName}
                       onChange={e => setSearch(p => ({...p, createUserName: e.target.value}))}/>
                <Select title={'库存状态'} defaultValue={'0'}
                        onChange={e => setSearch(p => ({...p, stokeWarningStatus: e}))}>
                    <Select.Option key={0} value={'0'}>全部</Select.Option>
                    <Select.Option key={1} value={'1'}>正常</Select.Option>
                    <Select.Option key={2} value={'2'}>预警</Select.Option>
                </Select>
                <Checkbox defaultChecked={true}
                          onChange={e => setSearch(p => ({...p, filterEmptyCard: e.target.checked}))}>过滤空年卡</Checkbox>
                {repo && hasPermission(['3', '4']) ?
                    <ExportButtonBySearch action={repo.cardExportBySearch} search={search} />
                    : null}
                <Button icon={<SearchOutlined/>}
                        onClick={() => setRequestPage(p => ({...p, offset: 0}))}/>
                {repo && hasPermission(['3', '4']) ? <ExportButton action={repo.cardExport}/> : null}
                {repo && hasPermission('4') ? <ImportButton url={repo.getUploadCardPath()}
                                                            headers={repo.getCommonHeaders()}
                                                            onCompleted={(r: ResponseData<string>) => {
                                                                console.log(r);
                                                                try {
                                                                    responseTransfer(r, v => v);
                                                                    setRequestPage({...requestPage});
                                                                } catch (e) {
                                                                    Modal.error({
                                                                        content: e?.toString()
                                                                    });
                                                                }
                                                            }}
                /> : null}
                {repo && hasPermission('4') ?
                    <Button icon={<RestOutlined/>} onClick={() => setOpenRecycle(true)}/> : null}
            </>}
            loading={loading}
            {...datasource}>
            <Table.Column
                title={<Typography.Text ellipsis>年卡</Typography.Text>}
                render={(e: Card) => <Cell {...e} coverName='年卡图'/>}/>
            <Table.Column
                title={<Typography.Text ellipsis>用户</Typography.Text>}
                render={(e: Card) => <InfoLine contentPlaceHolder='未分配'>
                    {e.user ? <Space><UserCell {...e.user}
                                               repo={repo}
                                               showPhone/>
                        {hasPermission(['2', '3', '4']) && repo ? <PhoneCustomer card={e} repo={repo}/> : null}
                    </Space> : null}
                </InfoLine>}/>
            <Table.Column
                title={<Typography.Text ellipsis>兑换信息</Typography.Text>}
                render={(e: Card) => <div className='column'>
                    <InfoLine label="可兑换"
                              contentPlaceHolder='未配置'>{(e.itemCount ?? 0) > 0 ? e.itemCount : null}</InfoLine>
                    <InfoLine label='发货次数'
                              contentPlaceHolder='无限制'>
                        {e.deliveryCount || e.consumeDeliveryCount ? <>{e.deliveryCount}{e.consumeDeliveryCount ? `(${e.consumeDeliveryCount}已使用)` : null}</> : null}
                    </InfoLine>
                    {e.expireTime ? <InfoLine label='有效期' contentPlaceHolder='永久'>
                        <Label>{
                            dayjs(new Date(e.expireTime)).format('YYYY/MM/DD')
                        }</Label>
                    </InfoLine> : null}
                </div>}
            />
            <Table.Column
                title={<Typography.Text ellipsis>开卡信息</Typography.Text>}
                render={(e: Card) => {
                    const value = e.value ? parseInt(e.value) : 0;
                    return <div className='column'>
                        <InfoLine label={e.externalNumber}/>
                        <InfoLine label="价格"
                                  contentPlaceHolder='未导入'>
                            {value > 0 ?
                                <span style={{color: 'green'}}>{e.currency?.symbol}{value.toLocaleString(undefined, {
                                    minimumFractionDigits: e.currency?.scale ?? 0,
                                })}</span> : undefined}
                        </InfoLine>
                        <InfoLine label="平台"
                                  contentPlaceHolder='无'>
                            {e.externalPlatform}
                        </InfoLine>
                    </div>;
                }}/>
            <Table.Column
                title={<Typography.Text ellipsis>顾问</Typography.Text>}
                render={(e: Card) => <InfoLine contentPlaceHolder='未设置'>
                    {e.agentUser ? <UserCell {...e.agentUser} showLogin={false} showPhone repo={repo}/> : null}
                </InfoLine>}/>
            <Table.Column
                align='right'
                className={styles.table}
                title={<Typography.Text ellipsis>库存</Typography.Text>}
                render={(e: Card) => <div className='column' style={{alignItems: 'flex-end'}}>
                    <Typography.Text style={{color: 'green'}}>{e.isStokeWarning ?
                        <span style={{color: 'red'}}>预警</span> : '正常'}</Typography.Text>
                    {e.summary ? <Label>{e.summary}</Label> : null}
                </div>}/>
            <Table.Column
                align='right'
                className={styles.table}
                title={<Typography.Text ellipsis>状态</Typography.Text>}
                render={(e: Card) => <div className='column' style={{alignItems: 'flex-end'}}>
                    <Typography.Text style={{color: 'green'}}>{
                        e.status === undefined || e.status < 0 ? <span style={{color: 'red'}}>禁用</span>
                            : '正常'}</Typography.Text>
                    {e.summary ? <Label>{e.summary}</Label> : null}
                </div>}/>
            {hasPermission(['3', '4']) ? <Table.Column
                align='right'
                title={<Typography.Text ellipsis>操作</Typography.Text>}
                render={(e: Card) => <Space>
                    <Button
                        type='primary'
                        onClick={() => setEditCard(e)}
                        size='small'>编辑</Button>
                    <Button type='primary' onClick={()=>{setIsRemindModalVisible(true),setYearCardId(e.id)}} size='small'>提醒</Button>
                    <Modal
                        title="提醒"
                        visible={isRemindModalVisible}
                        onCancel={() => setIsRemindModalVisible(false)}
                        footer={[
                            <Button key="cancel" onClick={() => setIsRemindModalVisible(false)}>
                                取消
                            </Button>,
                            <Button key="ok" type="primary" onClick={handleReminderSubmit}>
                                确定
                            </Button>,
                        ]}
                    >
                        <DatePicker
                            placeholder="提醒时间"
                            value={reminderTime ? dayjs(reminderTime) : null}
                            onChange={(date, dateString) => setReminderTime(dateString)}
                            showTime={{
                                format: 'HH:mm', // 只显示时和分
                                minuteStep: 1, // 设置分钟的步进值，如果需要
                            }}
                            format="YYYY-MM-DD HH:mm:00" // 使用中文显示月份并包括时和分
                            showSecond={false} // 不显示秒
                        />

                        <Input
                            placeholder="提醒内容"
                            value={reminderContent}
                            onChange={(e) => setReminderContent(e.target.value)}
                        />

                    </Modal>
                    {hasPermission('4') && repo ? <Popconfirm title={`删除年卡 ${e.name}？`}
                                                              placement='bottomRight'
                                                              onConfirm={() => {
                                                                  repo.cardStatus(e.id, 2)
                                                                      .catch(e => Modal.error({content: e}))
                                                                      .then(() => {
                                                                          const ni = datasource?.items.filter(v => v.id !== e.id);
                                                                          if (ni) setDatasource({
                                                                              ...datasource,
                                                                              items: ni
                                                                          });
                                                                      })
                                                              }}>
                        <Button
                            type='primary'
                            size='small'
                            danger>
                            删除
                        </Button>
                    </Popconfirm> : undefined}



                </Space>}/> : null}
        </CommonTable>
        <CardInput card={editCard}
                   agentUser={hasPermission('4') ? undefined : userSession?.user}
                   onComplete={card => {
                       setEditCard(undefined);
                       if (card && datasource) {
                           const index = datasource.items.findIndex(v => v.id === card.id);
                           let newItems;
                           if (index >= 0) {
                               datasource.items[index] = card;
                               newItems = [...datasource.items];
                           } else newItems = [card, ...datasource.items];
                           setDatasource({...datasource, items: newItems});
                       }
                   }}/>
        <Image
            width={200}
            style={{display: 'none'}}
            preview={{
                visible: previewImage !== undefined,
                src: previewImage,
                onVisibleChange: value => value ? undefined : setPreviewImage(undefined),
            }}
        />
        {repo
            ? <Recycle repo={repo} open={openRecycle}
                       onRestore={c => {
                           const items = [c, ...datasource?.items ?? []];
                           setDatasource({...datasource, items: items});
                       }}
                       onClose={() => setOpenRecycle(false)}/>
            : null}
    </>;
};

const PhoneCustomer = (props: {
    card: Card,
    repo: Repository
}) => <Popover trigger='click'
               placement='bottomRight'
               destroyTooltipOnHide
               content={<PhonePanel {...props}/>}>
    <Typography.Link>联系</Typography.Link>
</Popover>;

const PhonePanel = (props: {
    card: Card,
    repo: Repository
}) => {
    const {card, repo} = props;
    const [phone, setPhone] = useState<UserPhone>();
    useEffect(() => {
        setPhone(undefined);
        repo.getPhoneByCard(card.id)
            .then(setPhone)
            .catch(e => Modal.error({content: e}));
    }, [card.id, repo]);
    return <Space direction='vertical'>
        <Typography.Text>年卡 {card.number ?? card.id} 联系方式</Typography.Text>
        {phone ? <>
            <Typography.Text>使用手机 <Typography.Text strong
                                                   type='danger'>{phone.phone}</Typography.Text> 拨打</Typography.Text>
            <Typography.Text type='danger' strong
                             style={{fontSize: 24}}>{phone.secretPhone ?? phone.clientPhone}</Typography.Text>
        </> : <Spin size='large'/>}
    </Space>
}