import {Avatar, Button, Drawer, List, Image, Popconfirm, Modal} from "antd";
import {useEffect, useState} from "react";
import Repository from "@/repo/repository";
import {PageList, RequestPageInfo} from "@/repo/model/common";
import {Card} from "@/repo/model/card";
import {UndoOutlined, DeleteOutlined} from "@ant-design/icons";

export default (props: {
    repo: Repository,
    open: boolean,
    onRestore: (card: Card) => void,
    onClose: () => void}) => {
    const {open, repo, onClose, onRestore} = props;
    const [datasource, setDatasource] = useState<PageList<Card>>();
    const [requestPage, setRequestPage] = useState<RequestPageInfo>({offset: 0});
    useEffect(() => {
        if (open) repo.listCard({status: 1, page: requestPage}).then(setDatasource);
    }, [repo, open, requestPage]);
    return  <Drawer
        title="已删除年卡"
        onClose={onClose}
        placement='right'
        open={open}>
        <List pagination={{
            hideOnSinglePage: true,
            size: 'small',
            pageSize: datasource?.size,
            total: datasource?.count,
            onChange: (page, pageSize) => setRequestPage({...requestPage, offset: (page - 1) * pageSize, size: pageSize}),
            current: datasource?.offset != undefined
                ? Math.floor(datasource.offset / (datasource.size ?? 0)) + 1: undefined
        }}>
            {datasource?.items.map(e => <List.Item
                actions={[
                    <Popconfirm title={`恢复删除年卡 ${e.name}？`}
                                placement='bottomLeft'
                                onConfirm={() => repo.cardStatus(e.id, 1)
                                    .then(() => {
                                        const items = datasource?.items.filter(v => v.id !== e.id);
                                        if (items) setDatasource({...datasource, items: items});
                                    }).then(() => onRestore(e))}>
                        <Button key={0} size='small' icon={<UndoOutlined />}/>
                    </Popconfirm>,
                    <Popconfirm title={`永久删除年卡 ${e.name}？`}
                                placement='bottomLeft'
                                onConfirm={() => {
                                    repo.cardStatus(e.id, 3)
                                        .then(() => {
                                            const items = datasource?.items.filter(v => v.id !== e.id);
                                            if (items) setDatasource({...datasource, items: items});
                                        })
                                        .catch(e => Modal.error({content: e}));
                                }}>
                        <Button key={1} size='small' danger icon={<DeleteOutlined />}/>
                    </Popconfirm>
                ]}>
                <List.Item.Meta
                    avatar={<Avatar size={60}
                                      shape="square"
                                      style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center'}}
                                      icon={e.icon || e.cover ? <Image
                                          src={e.icon ?? e.cover}/> : null}>
                        {e.name?.substring(0, 1).toUpperCase()}
                    </Avatar>}
                    title={e.name}
                    description={e.externalNumber}
                />
            </List.Item>)}
        </List>
    </Drawer>
}
