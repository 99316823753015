import {useState, useLayoutEffect, MutableRefObject} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
export const useSize = (target: MutableRefObject<any>) => {
    const [size, setSize] = useState<DOMRectReadOnly>()
    useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect())
    }, [target]);
    useResizeObserver(target, (entry) =>
        setSize(entry.contentRect));
    return size;
}
