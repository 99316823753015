import {
    Button,
    DatePicker, Modal,
    Popconfirm
} from "antd";
import {useEffect, useState} from "react";
import {DownloadOutlined, ClockCircleOutlined} from "@ant-design/icons";
import dayjs, {Dayjs} from "dayjs";
import {PageList, RequestPageInfo} from "@/repo/model/common";

export default (props: {
    action: (options: {
        page?: RequestPageInfo,
        endTime: string,
        startTime?: string,
    }) => Promise<PageList<string>>}) => {
    const {action} = props;
    const [selectRange, setSelectRange] = useState<[Dayjs, Dayjs]>();
    const format = 'YYYY-MM-DD HH:mm:ss';
    useEffect(() => !selectRange ? setSelectRange([
        dayjs().subtract(3, 'months'), dayjs()
    ]) : undefined, [selectRange]);
    const downloadUrl = (url: string) => {
        if (!url) {
            Modal.error({content: '无法导出数据，未获取到数据地址。'});
            return;
        }
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    return <Popconfirm trigger="click"
                       icon={<ClockCircleOutlined style={{marginTop: 4}}/>}
                       onConfirm={selectRange ? async () => {
                           let page: RequestPageInfo|undefined = undefined;
                           try {
                               do {
                                   const list = await action({
                                       page: page,
                                       endTime: selectRange[1].format(format),
                                       startTime: selectRange[0].format(format)
                                   });
                                   if (list.items.length) downloadUrl(list.items[0]);
                                   const offset = list.offset ?? 0;
                                   const size = list.size ?? 0;
                                   const total = list.count ?? 0;
                                   page = offset + size < total ? {offset: offset + size, size: size} : undefined;
                               } while (page !== undefined);
                           } catch (e) { Modal.error({
                               content: e?.toString() ?? '导出数据错误，访问服务器失败。'
                           }); }
                       } : undefined}
                       style={{padding: 0}}
                       title={<DatePicker.RangePicker
                           dropdownAlign={{ offset: [0, 30] }} // 调整下拉面板的位置，使其向下偏移一定距离
                           value={selectRange}
                           ranges={{
                               '本周': [dayjs().startOf('week'), dayjs()],
                               '本月': [dayjs().startOf('month'), dayjs()],
                               '30天': [dayjs().subtract(30, 'day'), dayjs()],
                               '90天': [dayjs().subtract(90, 'day'), dayjs()],
                           }}
                           onCalendarChange={v => setSelectRange(v as [Dayjs, Dayjs]|undefined)}
                           showTime={false}
                           format={format}
                       />} placement={'bottomRight'}>
        <Button icon={<DownloadOutlined/>}/>
    </Popconfirm>;
}
