import styles from './index.module.css';
import {Row, Button, Input, Space, Col, Checkbox, notification, Spin, Typography  } from "antd";
import {useEffect, useState} from "react";
import {useUserSessionContext} from "@/repo/context";
import {VerifyCode} from "@/repo/model/common";

const VERIFY_CODE_KEY_ITEM = "VERIFY_CODE_KEY";

const Login = () => {
    const [inputLogin, setInputLogin] = useState<string>();
    const [inputPassword, setInputPassword] = useState<string>();
    const [verifyCode, setVerifyCode] = useState<VerifyCode & {error?: any}>({});
    const [rememberLogin, setRememberLogin] = useState(true);
    const [committing, setCommitting] = useState(false);
    const [requestVerifyCodeCount, setRequestVerifyCodeCount] = useState(0);
    const {repo, userSession, updateUserSession} = useUserSessionContext();
    useEffect(() => {
        setInputLogin(undefined);
        setInputPassword(undefined);
        setRememberLogin(true);
        setCommitting(false);
    }, [userSession]);
    useEffect(() => {
        const key = verifyCode.key ?? localStorage.getItem(VERIFY_CODE_KEY_ITEM) ?? undefined;
        repo?.getImageVerifyCode(key)
            .then(code => {
                setVerifyCode(code);
                console.log(`set key: ${code.key}`);
                code.key ? localStorage.setItem(VERIFY_CODE_KEY_ITEM, code.key) : localStorage.removeItem(VERIFY_CODE_KEY_ITEM);
            })
            .catch(e => setVerifyCode({error: e}));
    }, [repo, requestVerifyCodeCount]);
    return <Space className={styles.noBackground} direction="vertical" size={30}>
        <Row className={styles.title} justify="center">登录</Row>
        <div/>
        <Input placeholder="账号" size="large" autoFocus onChange={v => setInputLogin(v.target.value)}/>
        <Input.Password placeholder="登录密码" size="large"
                        onChange={v => setInputPassword(v.target.value)}/>
        <Row align="middle" gutter={20}>
            <Col span={12}><Input placeholder="验证码" size="large"
                                  value={verifyCode.code}
                                  onChange={v => setVerifyCode(prev => ({
                                      ...prev,
                                      code: v.target.value
                                  }))}/></Col>
            <Col span={12}>
                <div onClick={() => setRequestVerifyCodeCount(prev => prev + 1)}
                     className={styles.verifyCode}
                     style={{backgroundImage: `url(${verifyCode.image})`}}>
                    {verifyCode.error ? <Typography.Text>获取验证码失败，点击重试</Typography.Text>
                        : !verifyCode.image ? <Spin size='small'/> : null}
                </div>
            </Col>
        </Row>
        <div/>
        <Button type="primary"
                size="large"
                loading={!repo || committing}
                disabled={!inputLogin || !inputPassword || !verifyCode.code}
                onClick={repo
                && inputLogin
                && inputPassword
                && verifyCode.code ? () => {
                    setCommitting(true);
                    repo.login(inputLogin, inputPassword, {key: verifyCode.key ?? '', code: verifyCode.code ?? ''})
                        .then(u => {
                            updateUserSession(u);
                            if (rememberLogin) repo.storeSession();
                        })
                        .catch(e => {
                            setCommitting(false)
                            const r = e.response;
                            let description = e.toString();
                            if (r) {
                                switch (r.status) {
                                    case 406:
                                        description = '账号或密码不正确';
                                        break;
                                    case 412:
                                        description = '验证码错误';
                                        setRequestVerifyCodeCount(p => p + 1);
                                        break;
                                    case 428:
                                        description = '需要验证';
                                        break;
                                    default:
                                        description = r.data ? JSON.stringify(r.data) : `${r.status}: [${r.statusText}]`
                                        break;
                                }
                            }
                            notification.error({
                                message: '登录失败',
                                description: description});
                        });
                } : undefined}
                block
                className={styles.button}>登录</Button>
        <Row justify='end'>
            <Checkbox
                checked={rememberLogin}
                onChange={v => setRememberLogin(v.target.checked)}>记住登录</Checkbox>
        </Row>
    </Space>;
}

export default Login;