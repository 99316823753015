import {
    Button,
    DatePicker, Modal,
    Popconfirm, PopconfirmProps, Tooltip
} from "antd";
import {useEffect, useState} from "react";
import {DownloadOutlined, ClockCircleOutlined} from "@ant-design/icons";
import dayjs, {Dayjs} from "dayjs";
import {PageList, RequestPageInfo} from "@/repo/model/common";

export default (props: {
    action: (options: {
        page?: RequestPageInfo,
        search: {
            createUserName?: string,
            createUserPhone?: string,
            userName?: string,
            userPhone?: string,
            yearCardOrderNo?: string,
            stokeWarningStatus?: string,
            filterEmptyCard?: boolean
        }
    }) => Promise<PageList<string>>,
    search: {
        createUserName?: string,
        createUserPhone?: string,
        userName?: string,
        userPhone?: string,
        yearCardOrderNo?: string,
        stokeWarningStatus?: string,
        filterEmptyCard?: boolean
    }
}) => {
    const { action, search } = props;

    useEffect(() => {
        // Your code for setting default values or any other necessary logic with the search variable
    }, [search]);

    const downloadUrl = (url: string) => {
        if (!url) {
            Modal.error({ content: '无法导出数据，未获取到数据地址。' });
            return;
        }
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleExport = async () => {
        let page: RequestPageInfo | undefined = undefined;
        try {
            do {
                const list = await action({
                    page,
                    search
                });
                if (list.items.length) downloadUrl(list.items[0]);
                const offset = list.offset ?? 0;
                const size = list.size ?? 0;
                const total = list.count ?? 0;
                page = offset + size < total ? { offset: offset + size, size } : undefined;
            } while (page !== undefined);
        } catch (e) {
            Modal.error({
                content: e?.toString() ?? '导出数据错误，访问服务器失败。'
            });
        }
    };

    return (
        <Popconfirm
            title="确认导出数据吗？"
            trigger="click"
            onConfirm={handleExport}
            okText="确认"
            cancelText="取消"
            style={{ padding: 0 }}
            placement='bottomRight'
        >
            <Tooltip title="导出查询结果">
                <Button icon={<DownloadOutlined />}/>
            </Tooltip>
        </Popconfirm>
    );


};