import {Card} from '../model/card';
import { Good } from '../model/good';
import { Order } from '../model/order';
import { User, UserSession } from '../model/user';
import { CardResponse } from './card';
import { ResponseData, TimeResponse} from './common';
import { LoginResponse } from './login';
import { OrderResponse } from './order';
import {ProductResponse, ProductResponseByAdmin} from './product';
import { UserResponse } from './user';
import moment, {Dayjs} from "dayjs";
import { defaultCurrency } from '../model/currency';

export function responseTransfer<T, R>(response: ResponseData<T>, convert: (data: T) => R, onError?: (r: ResponseData<any>) => void): R {
    if (response.code !== 1) if (onError) onError(response); else throw response.message;
    return convert(response.data);
}

export function userResponseTransfer(v: UserResponse): User {
    return {
        id: v.id.toString(),
        login: v.name,
        name: v.weixinName,
        phone: v.phone,
        avatar: v.imgUrl,
        cardCount: v.yearCardCount,
        permissions: v.userRoles?.map(v => v.toString()),
        permissionCount: v.userRoles?.length,
        status: v.isAgreeProtocol ? 1 : 0,
        encrypt: {
            name: v.encryptName,
            phone: v.encryptPhone
        }
    }
}

export function loginResponseTransfer(v: LoginResponse): UserSession {
    const user = userResponseTransfer(v);
    return {
        user: user,
        payId: '',
        token: v.token,
        expireTime: '',
        permissions: user.permissions ?? [],
    }
}

export function cardResponseTransfer(v: CardResponse): Card {
    let present: string|undefined;
    let count = v.productList?.length;
    const items = v.productList?.map(p => {
        const type = p.productType;
        if (type === 2) {
            present = p.content;
            count--;
        }
        return {
            good: goodResponseTransfer(p),
            count: p.count,
            consume: p.orderUsedCount
        }
    });
    return {
        id: v.id.toString(),
        createTime: timeResponseTransfer(v.boughtAt),
        items: items,
        name: v.name ?? v.yearCardName,
        cover: v.imgUrl,
        birthdayPresent: present,
        deliveryCount: v.lastTimes,
        externalNumber: v.orderNo,
        externalPlatform: v.platform,
        card: v.yearCardId ? { id: v.yearCardId.toString() } : undefined,
        user: v.userInfoView ? userResponseTransfer(v.userInfoView) : v.userId ? { id: v.userId.toString() } : undefined,
        itemCount: count,
        agentUser: v.createUserName ? { id: '',
            name: v.createUserName,
            phone: v.createUserPhone
        } : undefined,
        company: v.companyTagView ? {
            id: v.companyTagView.id.toString(),
            name: v.companyTagView.name
        } : undefined,
        value: v.money ? (v.money / 100).toString() : undefined,
        currency: defaultCurrency,
        status: 1,
        isStokeWarning: v.isStokeWarning
    }
}

export function goodResponseTransfer(v: ProductResponse): Good {
    return {
        id: v.id.toString(),
        name: v.name,
        icon: v.imgUrl,
        description: v.content,
        cover: v.horizontalImgUrl,
        status: 0
            // v.productType === 2 ? -1 : 0
    }
}

export function goodResponseTransferByAdminProduct(v: ProductResponseByAdmin): Good {
    return {
        id: v.id.toString(),
        name: v.name,
        icon: v.imgUrl,
        description: v.content,
        cover: v.horizontalImgUrl,
        status: v.status
    }
}

export function orderResponseTransfer(v: OrderResponse): Order {
    const card =  v.yearCardInfo ? cardResponseTransfer(v.yearCardInfo) : undefined;
    const status = v.status;
    return {
        name: v.orderNo,
        number: v.id.toString(),
        createTime: timeResponseTransfer(v.createAt),
        modifyTime: timeResponseTransfer(v.updateAt),
        finishTime: timeResponseTransfer(v.finishAt),
        processStatus: v.afterSaleStatus,
        summary: v.sellerRemark ?? v.remark,
        card: card,
        status: status === 4 ? 127 : status === 5 ? -127 : status,
        user: v.buyerUser ? userResponseTransfer(v.buyerUser) : v.buyerUserId ? {
            id: v.buyerUserId.toString(),
            phone: v.buyerPhone,
        } : undefined,
        itemCount: v.products?.length,
        items: v.products?.map(p => ({
            count: p.count,
            card: card,
            good: goodResponseTransfer(p),
        })),
        deliveries: [{
            number: v.externalBillNum,
            createTime: timeResponseTransfer(v.deliveryAt),
            modifyTime: timeResponseTransfer(v.deliveryAt),
            name: v.receiverName,
            phone: v.receiverPhone,
            address: v.receiverDetailAddress,
            addressLine1: v.receiverCityName,
            addressLine2: v.receiverProvinceName,
            externalNumber: v.deliveryOrderNo,
            externalPlatform: v.deliveryOrderAgent,
            status: 0,
            encrypt: v.encryptReceiverName || v.encryptReceiverPhone || v.encryptAddress ? {
                name: v.encryptReceiverName ?? v.receiverName,
                phone: v.encryptReceiverPhone ?? v.receiverPhone,
                address: v.encryptAddress ?? v.receiverDetailAddress,
            } : undefined
        }, ],
    };
}

export function timeResponseTransfer(v?: TimeResponse|number|string): string|undefined {
    if (!v) return undefined;
    if (typeof v === 'number') {
        const d = new Date();
        d.setTime(v * 1000);
        return d.toISOString();
    } else if (typeof v === 'string') return v;
    const m = moment();
    m.set('year', v.year);
    m.set('month', v.month);
    m.set('day', v.day);
    m.set('hour', v.hours);
    m.set('minute', v.minutes);
    m.set('second', v.seconds);
    return m.toISOString();
}

export function toTimeResponse(v?: Dayjs): TimeResponse {
    v = v ?? moment();
    return {
        nanos: 0,
        timezoneOffset: 0,
        time: 0,
        date: v.date(),
        day: v.day(),
        month: v.month(),
        year: v.year(),
        hours: v.hour(),
        minutes: v.minute(),
        seconds: v.second()
    }
}