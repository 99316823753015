import {Button, Modal, Upload} from "antd";
import {useState} from "react";
import {UploadOutlined} from "@ant-design/icons";
import {HttpRequestHeader} from "antd/lib/upload/interface";

export default (props: {
    url: string,
    headers?: HttpRequestHeader,
    onCompleted?: (response: any) => void,
}) => {
    const {url, headers, onCompleted} = props;
    const [loading, setLoading] = useState(false);
    return <Upload action={url}
                   headers={headers}
                   showUploadList={false}
                   onChange={info => {
                       const status = info.event ? undefined : info.file.status;
                       switch (status) {
                           case 'uploading':
                               setLoading(true);
                               break;
                           case 'done':
                               setLoading(false);
                               onCompleted?.(info.file.response);
                               break;
                       }
                   }}
                   accept='*/*'
                   maxCount={1}>
        <Button
            loading={loading}
            icon={loading ? undefined : <UploadOutlined/>}/>
    </Upload>;
}