import {Typography} from "antd";
import {CSSProperties, ReactNode} from "react";
import styles from './index.module.css';

export const disableStyle = {color: 'rgba(0,0,0,0.2)'};
export const InfoLine = (props: {label?: ReactNode,
    contentPlaceHolder?: ReactNode,
    multipleLine?: boolean,
    action?: () => void;
    actionIcon?: ReactNode;
    actionTooltip?: ReactNode;
    copyable?: boolean;
    children?: ReactNode}) => {
    const {contentPlaceHolder,
        label,
        multipleLine,
        action,
        actionIcon,
        actionTooltip,
        copyable,
        children} = props;
    const content = <Typography.Text ellipsis={multipleLine !== true}
                                       editable={action || actionIcon ? {
                                           icon: actionIcon,
                                           tooltip: actionTooltip,
                                           editing: false,
                                           onStart: action
                                       } : undefined}
                                       copyable={copyable}
                                       style={!children ? disableStyle : undefined}>
        {children ?? contentPlaceHolder}
    </Typography.Text>;
    return <div className={label ? styles.line : undefined}>
        {label ? <Label style={{marginRight: 6, minWidth: 30}}>{label}</Label> : null}
        {content}
    </div>;
};

export const Label = (props: {children?: ReactNode,
    style?: CSSProperties
}) => <Typography.Text
    style={props.style}
    className={styles.label}
    type="secondary"
    ellipsis>{props.children}</Typography.Text>;
