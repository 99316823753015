import {useEffect, useState, ReactNode} from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    AreaChartOutlined,
    DashboardOutlined,
    AccountBookOutlined,
    PieChartOutlined,
    DesktopOutlined,
    UserSwitchOutlined,
    TeamOutlined,
    ShoppingOutlined,
    ProfileOutlined, MoreOutlined, LogoutOutlined, LockOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import {
    Button,
    Layout,
    Menu,
    Breadcrumb,
    Modal,
    Spin,
    Typography,
    Avatar,
    Popover,
    Badge,
    Drawer, List
} from 'antd';
import styles from './App.module.css';
import {BrowserRouter, Link, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import DataBrief from "./view/data-brief";
import Good from "./view/good";
import Log from "./view/log";
import Order from "./view/order";
import User from "./view/user";
import Card from "./view/card";
import Login from "./component/login";
import {useUserSessionContext} from "@/repo/context";
import Repository from "@/repo/repository";
import {UserSession} from "@/repo/model/user";
import ChangePasswordDialog from "@/component/dialog/change-password";
import {PageList} from "@/repo/model/common";
import {MessageResponse} from "@/repo/response/message";
// import logo from './logo.svg';

interface MenuData {
    key: string;
    path: string;
    label: string;
    danger?: boolean;
    icon?: ReactNode;
    children?: MenuData[];
    content?: ReactNode;
}

interface MenuSelect {
    selected: string[];
    expanded: string[];
}

const homeMenu: MenuData = {
    key: '/',
    path: '',
    label: '',
    content: <div/>
};

const menus: MenuData[] = [{
    key: '',
    path: 'data',
    label: '内容运营',
    icon: <DashboardOutlined />,
    children: [{
        key: '',
        path: 'brief',
        label: '数据概览',
        icon: <PieChartOutlined />,
        content: <DataBrief/>
    }, {
        key: '',
        path: 'recommend',
        label: '推荐商品',
        icon: <AreaChartOutlined />,
        content: <DataBrief/>
    }]}, {
    key: '',
    path: 'transaction',
    label: '交易管理',
    icon: <UserSwitchOutlined />,
    children: [{
        key: '',
        label: '订单列表',
        path: 'order',
        icon: <ProfileOutlined />,
        content: <Order/>
    },]}, {
    key: '',
    path: 'platform',
    label: '平台管理',
    icon: <DesktopOutlined />,
    children: [{
        key: '',
        label: '用户管理',
        path: 'user',
        icon: <TeamOutlined />,
        content: <User/>
    }, {
        key: '',
        icon: <AccountBookOutlined />,
        label: '年卡管理',
        path: 'card',
        content: <Card/>
    }, {
        key: '',
        label: '商品管理',
        path: 'good',
        icon: <ShoppingOutlined />,
        content: <Good/>
    }, {
        key: '',
        label: '操作日志',
        path: 'log',
        icon:<InfoCircleOutlined />,
        content: <Log/>
    }]}];

function processMenuData(data: MenuData, parentPath?: string): MenuData {
    const currentPath = parentPath ? `${parentPath}/${data.path}` : data.path;
    return {
        ...data,
        key: currentPath,
        children: data.children?.map(v => processMenuData(v, currentPath))
    }
}

function create(path: String, items: MenuData[], keys?: {
    selected?: string[],
    expanded?: string[]
}): MenuSelect {
    let rk = {
        selected: keys?.selected ?? [],
        expanded: keys?.expanded ?? [],
    }
    const {selected, expanded} = rk;
    items.forEach(v => {
        const k = v?.key?.toString();
        if (k && path.startsWith(k)) {
            if (v.children) {
                expanded.push(k);
                rk = create(path, v.children, rk);
            } else selected.push(k);
        }
    });
    return rk;
}

const Body = (prop: {
    repo: Repository,
    session: UserSession,
    onLogout: () => void }) => {
    const {repo, session, onLogout} = prop;
    const [collapsed, setCollapsed] = useState(false);
    const [enableExpanded, setEnableExpanded] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const [menuItems, setMenuItems] = useState<MenuData[]>();
    const [menuSelect, setMenuSelect] = useState<MenuSelect>();
    const [currentMenuData, setCurrentMenuData] = useState<MenuData>();
    const user = session.user;
    useEffect(() => {
        const newItems = menus.map(v => processMenuData(v));
        let path = location.pathname;
        if (path.startsWith('/')) path = path.substring(1);
        setMenuItems(newItems);
        setMenuSelect(path.length > 0 ? create(path, newItems) : undefined);
    }, [location]);
    useEffect(() => {
        if (collapsed) setEnableExpanded(false); else {
            const timer = setTimeout(() => setEnableExpanded(!collapsed), 100);
            return () => clearTimeout(timer);
        }
    }, [collapsed]);
    return <><Layout className={styles.body}>
        <Layout.Sider trigger={null}
                      breakpoint="lg"
                      onBreakpoint={setCollapsed}
                      collapsible
                      collapsed={collapsed}>
            <div className={styles.logo} />
            <Menu
                onClick={e => navigate(e.key)}
                selectedKeys={menuSelect?.selected}
                openKeys={enableExpanded ? menuSelect?.expanded : undefined}
                onOpenChange={v => collapsed ? undefined : setMenuSelect(p => ({
                    selected: p?.selected ?? [],
                    expanded: v.length > 0 ? [v[v.length - 1]] : v,
                }))}
                items={menuItems}
                theme="dark"
                mode="inline"/>
        </Layout.Sider>
        <Layout>
            <Layout.Header style={{padding: 0, background: 'white'}} className={styles.title}>
                <Button icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                        size="large"
                        type="text"
                        onClick={() => setCollapsed(p => !p)}/>
                <Breadcrumb style={{flex: 1}}>
                    {currentMenuData?.path
                        ? <>
                            <Breadcrumb.Item><Link to={''}>主页</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{currentMenuData.label}</Breadcrumb.Item>
                        </> : <Breadcrumb.Item>主页</Breadcrumb.Item>}
                </Breadcrumb>
                <Avatar src={user.avatar} style={{marginRight: 10}}>
                    {(user.name ?? user.id).substring(0, 1).toUpperCase()}
                </Avatar>
                <Typography.Text>{user.name}</Typography.Text>
                <UserContextMenu onLogout={onLogout} repo={repo}/>
            </Layout.Header>
            <Layout.Content className={styles.layoutContent}>
                <div className={styles.content}>
                    <Routes>
                        {menuItems?.flatMap(v => [v, ...(v.children ?? [])])
                            .map(v => v?.content ? <Route
                                key={v.key}
                                path={`/${v.key}`}
                                element={<RouteContent
                                    item={v}
                                    onInit={setCurrentMenuData}>{v.content}</RouteContent>}
                            /> : null)}
                        <Route key={homeMenu.key} path={'/'} element={
                            <RouteContent
                                item={homeMenu}
                                onInit={setCurrentMenuData}>{homeMenu.content}</RouteContent>
                        }/>
                    </Routes>
                </div>
            </Layout.Content>
        </Layout>
    </Layout>
    </>;
};

const UserContextMenu = (props: { onLogout: () => void, repo: Repository }) => {
    const {onLogout, repo} = props;
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messages, setMessages] = useState<PageList<MessageResponse>>();
    useEffect(() => {
       repo.listMessage().then(setMessages);
    }, [repo]);
    const messageCount = messages?.items.filter(v => !v.hasRead).length ?? 0;
    return <>
        <Popover placement="topRight"
                 destroyTooltipOnHide
                 open={showContextMenu && !showChangePassword && !showMessage}
                 onOpenChange={setShowContextMenu}
                 trigger='click'
                 content={<Menu items={[{
                         key: '0',
                     label: <Badge count={messageCount}
                                   size='small'
                                   offset={[4, 0]}>信息</Badge>,
                         onClick: () => setShowMessage(true),
                         icon: <InfoCircleOutlined/>
                     }, {
                         key: '1',
                         label: '更改密码',
                         onClick: () => setShowChangePassword(true),
                         icon: <LockOutlined/>
                     }, {
                         key: '2',
                         label: '退出',
                         onClick: onLogout,
                         icon: <LogoutOutlined/>
                     }]}/>}>
            <Badge offset={[-14,14]} dot={messageCount > 0}>
                <Button type='text'
                        onClick={() => setShowContextMenu(p => !p)}
                        style={{margin: 10}}
                        icon={<MoreOutlined/>}>
                </Button>
            </Badge>
        </Popover>
        <ChangePasswordDialog open={showChangePassword} onClose={() => {
            setShowChangePassword(false);
            setShowContextMenu(false);
        }} onChange={(p, np) => repo.changePassword(p, np)}/>
        <Drawer
            title="信息"
            placement='right'
            bodyStyle={{paddingTop: 0, paddingRight: 0}}
            onClose={() => {
                setShowMessage(false);
                setShowContextMenu(false);
            }}
            destroyOnClose
            open={showMessage}>
            <UserMessageContent
                repo={repo}
                initData={messages?.items}
                onRead={m => {
                    if (messages) {
                        const ni = messages.items.findIndex(v => v.id === m.id);
                        if (ni >= 0) {
                            const nitems = [...messages.items];
                            nitems[ni] = m;
                            setMessages({...messages, items: nitems});
                        }
                    }
                }}
            />
        </Drawer>
    </>
}

const UserMessageContent = (props: {
    repo: Repository,
    initData?: MessageResponse[],
    onRead: (m: MessageResponse) => void
}) => {
    const {initData, repo, onRead} = props;
    const [messages, setMessages] = useState<PageList<MessageResponse>>()
    useEffect(() => {
        setMessages({items: initData ?? []});
        console.log('load')
    }, [repo, initData]);
    return <List size='small' dataSource={messages?.items}
                 renderItem={v => <UserMessageItem
                     message={v}
                     repo={repo}
                     onRead={() => {
                         const items = messages?.items;
                         if (items) {
                             const findIndex = items.findIndex(e => e.id === v.id);
                             if (findIndex >= 0) {
                                 const ni = [...items];
                                 ni[findIndex] = {...v, hasRead: true}
                                 setMessages({...messages, items: ni});
                             }
                         }
                         onRead(v);
                     }}
                 /> }>
    </List>;
};

const UserMessageItem = (props: {
    message: MessageResponse,
    repo: Repository,
    onRead: () => void
}) => {
    const {repo, message, onRead} = props;
    const [loading, setLoading] = useState(false);
    const { id, title, content,  hasRead} = message;
    return <List.Item
        style={hasRead ? {opacity: 0.4} : undefined}
        key={id}
        extra={hasRead ? undefined : <Button
            loading={loading}
            disabled={hasRead}
            onClick={() => {
                setLoading(true);
                repo.markMessageRead(id)
                    .then(onRead)
                    .finally(() => setLoading(false));
            }}
            type="link"
            size='small'>已读</Button>}>
        <List.Item.Meta title={title}
                        description={<Typography.Text
                            style={{fontSize: 10}}
                            type='secondary'>
                            {content}
                        </Typography.Text>}/>
    </List.Item>;
}

const RouteContent = (props: {
    item: MenuData,
    onInit: (item: MenuData) => void,
    children: ReactNode}) => {
    const {item, children, onInit} = props;
    useEffect(() => onInit(item), [item]);
    return <>{children}</>;
};

const LoginBody = (props: { onLogin: () => void }) => {
    const {onLogin} = props;
    return <Modal open={true} closable={false}
           centered
           width={'auto'}
           footer={null}>
        <Login/>
    </Modal>
}

export default () => {
    const {userSession, repo, updateRepo, updateUserSession} = useUserSessionContext();
    useEffect(() => {
        const repo = new Repository();
        if (repo.restoreSession()) repo.info()
            .then(updateUserSession)
            .catch(e => repo.removeSession())
            .finally(() => updateRepo(repo)); else updateRepo(repo);
    }, []);
    return repo ? <BrowserRouter>
        {userSession ? <Body session={userSession}
                             repo={repo}
                             onLogout={() => {
            updateUserSession(undefined);
            repo.removeSession();}}/>
            : <LoginBody onLogin={() => {}} />}
    </BrowserRouter> : <div className={styles.loading}><Spin size="large"/></div>;
}
