import {
    Input,
    Form,
    Space,
    Button,
    Modal
} from "antd";
import {useUserSessionContext} from "@/repo/context";
import {useState} from "react";
import {Order} from "@/repo/model/order";
import OrderItemView from './order-item';
import {useForm} from "antd/lib/form/Form";

export default (props: {
    order?: Order,
    onComplete: (order?: Order) => void
}) => {
    const {order, onComplete} = props;
    const status = order?.status ?? 0;
    const processStatus = order?.processStatus;
    const {repo} = useUserSessionContext();
    const [form] = useForm();
    const [committing, setCommitting] = useState(0);
    const isCheck = processStatus === 1 || status === 1;
    return <Modal open={order !== undefined}
                  title={processStatus === 1 ? '售后审核' : status === 1 ? '发货审核' : status === 2 ? '发货' : ''}
                  onCancel={() => onComplete()}
                  confirmLoading={committing > 0}
                  onOk={() => {
                      setCommitting(1);
                      form.submit();
                  }}
                  footer={isCheck ? <Space>
                      <Button danger
                              type='primary'
                              disabled={!repo || !order}
                              onClick={() => {
                                  setCommitting(2);
                                  form.submit();
                              }}>拒绝</Button>
                      <Button type='primary'
                              disabled={!repo || !order}
                              onClick={() => {
                                  setCommitting(1);
                                  form.submit();
                              }}>通过</Button>
                  </Space> : undefined}>
        <Form form={form} style={{marginBottom: 10}}
              onFinishFailed={() => setCommitting(0)}
              onFinish={order && repo ? values => {
                  const params = {...values, id: order.number, type: committing};
                  const p = processStatus === 1 ? repo.orderRefundCheck(params).then(() => ({
                            ...order,
                          processStatus: committing === 1 ? 3 : 2
                      })) : status === 1 ? repo.orderCheck(params).then(() => ({
                          ...order,
                          status: committing === 1 ? 2 : -2}))
                      : status === 2 ? repo.orderDelivery(params).then(() => ({
                          ...order,
                          deliveries: [{
                              ...(order?.deliveries && order?.deliveries[0] ? order.deliveries[0] : null),
                              number: params.number,
                              externalPlatform: params.platform,
                              externalNumber: params.platformNumber,
                              createTime: new Date().toISOString(),
                          }],
                          status: 3,
                      })) : null;
                  if (p) p
                      .then(t => onComplete(t))
                      .catch(e => Modal.warning({
                      title: '操作失败',
                      content: e ? e.toString() : ''
                  })).finally(() => setCommitting(0)); else setCommitting(0);
              } : undefined}
              validateMessages={{
                  whitespace: '输入内容',
                  required: '补齐需要的内容'
              }}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}>
            {status === 2 && processStatus !== 1 ? <>
                <Form.Item label='物流单号' required rules={[{required: true}]} name='platformNumber'>
                    <Input placeholder="发货物流单号"/>
                </Form.Item>
                <Form.Item label='物流平台' required rules={[{required: true}]} name='platform'>
                    <Input placeholder="物流平台名称"/>
                </Form.Item>
                <Form.Item label='关联单号' name='number'>
                    <Input placeholder="关联其他平台的单号"/>
                </Form.Item>
            </> : <Form.Item label='原因' required rules={[{required: true}]} name='reason'>
                <Input.TextArea placeholder="原因"/>
            </Form.Item>}
        </Form>
        {order && repo ? <OrderItemView order={order} repo={repo} /> : null}
    </Modal>;
}
