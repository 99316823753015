import axios, { AxiosRequestConfig } from "axios";
import {PageList, RequestPageInfo, VerifyCode} from "./model/common";
import {User, UserPhone, UserSession} from "./model/user";
import {Card, CardItem} from "./model/card";
import {Good} from "./model/good";
import {Order, OrderItem} from "./model/order";
import {RecordList, ResponseData} from "@/repo/response/common";
import {ProductResponse} from "@/repo/response/product";
import {CardResponse} from "@/repo/response/card";
import {OrderResponse} from "@/repo/response/order";
import {Log} from "@/repo/model/log";
import {LogResponse} from "@/repo/response/log";
import {
    cardResponseTransfer, goodResponseTransfer, goodResponseTransferByAdminProduct,
    loginResponseTransfer, orderResponseTransfer,
    responseTransfer, timeResponseTransfer,
    userResponseTransfer
} from "@/repo/response/transfer";
import {DeliveryResponse, TrackResponse} from "@/repo/response/deliviery";
import {MessageResponse} from "@/repo/response/message";

const LOGIN_SESSION = 'login-session';
const ACCESS_TOKEN_KEY = 'access-token';

class Repository {

    // private readonly appUrl = process.env.REACT_APP_HOST_URL!;
     private readonly appUrl = 'https://api.fivedoctors.online';
    // private readonly appUrl = 'https://t.fivedoctors.online';
    // private readonly appUrl = 'http://localhost:8091';
    private session: { accessToken?: string; } = {};

    constructor() {
        this.restoreSession();
    }

    getUploadImagePath() {
        return this.processUrl('admin/file/oss/images');
    }

    getUploadCardPath() {
        return this.processUrl('admin/yearCard/import');
    }

    getCommonHeaders() {
        const {accessToken} = this.session;
        const r: { [key: string]: string; } = {};
        if (accessToken) r[ACCESS_TOKEN_KEY] = accessToken;
        return r;
    }

    restoreSession(): boolean {
        this.session = JSON.parse(localStorage.getItem(LOGIN_SESSION) ?? '{}');
        return this.session.accessToken !== undefined;
    }

    storeSession() {
        localStorage.setItem(LOGIN_SESSION, JSON.stringify(this.session));
    }

    removeSession() {
        localStorage.removeItem(LOGIN_SESSION);
    }

    setSession(session: {token?: string}) {
        this.session.accessToken = session.token ?? this.session.accessToken;
    }

    processUrl(...paths: string[]): string {
        if (paths.length <= 0) return this.appUrl;
        const first = paths[0];
        return joinUrl(first.indexOf(':/') > 0 ? '' : this.appUrl, ...paths);
    }

    private async axiosRequest(params: AxiosRequestConfig, needAuth: boolean) {
        params.url = this.processUrl(params.url ?? '');
        const headers: any = { ...params.headers };
        const s = this.session;
        if (needAuth && s.accessToken != null) headers[ACCESS_TOKEN_KEY] = s.accessToken;
        params.headers = headers;
        return axios(params);
        // if (v.status >= 400) throw v;
    }

    private async requestListApi<T = any>(params: AxiosRequestConfig, needAuth = true): Promise<PageList<T>> {
        const response = (await this.axiosRequest(params, needAuth));
        const { offset, size, count } = response.headers;
        return {
            offset: parseInt(offset ?? '0'),
            size: size ? parseInt(size) : undefined,
            count: count ? parseInt(count) : undefined,
            items: Array.isArray(response.data) ? response.data : []
        };
    }

    private async requestApi<T = any>(params: AxiosRequestConfig, needAuth = true): Promise<T> {
        const d = (await this.axiosRequest(params, needAuth)).data;
        return d as T
    }

    login = (login: string, password: string, verify: {
        key: string,
        code: string
    }): Promise<UserSession> => this.requestApi({
        url: 'admin/login',
        method: 'POST',
        data: {
            phone: login,
            password: password,
            codeKey: verify.key,
            code: verify.code
        }
    }).then(v => responseTransfer(v, loginResponseTransfer));

    info = (): Promise<UserSession> => this.requestApi({ url: 'admin/userInfo', method: 'GET' })
        .then(r => responseTransfer(r, loginResponseTransfer));

    changePassword = (password: string, newPassword: string) => this.requestApi({
        url: 'admin/user/pwd',
        method: 'PUT',
        data: {
            curPassword: password,
            newPassword: newPassword
        }
    }).then(r => responseTransfer(r, item => item));

    listUser = (options?: {
        page?: RequestPageInfo,
        search?: string;
        fillPermissionCount?: boolean;
        fillCardCount?: boolean;
    }): Promise<PageList<User>> => this.requestApi({
        url: `admin/user${requestParams({...(pageTransfer(options?.page)), 
            phone: options?.search,
            ...options})}`,
        method: 'GET',
        headers: createPageHeader(options)
    }).then(r => listResponseTransfer(r, userResponseTransfer));

    getOperationLogDetail = (logId: string) => this.requestApi({
        url: `admin/logs/${logId}`,
    }).then(r => responseTransfer(r, v => v as LogResponse));

    listOperationLog = (options?: {
        page?: RequestPageInfo,
        startAt?: string,
        endAt?: string,
        phone?: string,
        orderNo?: string,
        type?: number,
    }) => this.requestApi({
        url: `admin/logs${requestParams({...(pageTransfer(options?.page)), ...options })}`,
        method: 'GET',
        headers: createPageHeader(options)
    }).then(r => listResponseTransfer(r, v => v as LogResponse));

    mergeUser = (userId: string, phone: string) => this.requestApi({
        url: '/admin/user/merge',
        method: 'PUT',
        data: {
            phone: phone,
            userId: userId,
        }
    }).then(r => responseTransfer(r, userResponseTransfer));

    updateUserPhone = (userId: string, phone: string) => this.requestApi({
        url: `admin/user/${userId}/phone`,
        method: 'PUT',
        data: {phone: phone}
    }).then(r => responseTransfer(r, userResponseTransfer));

    updateUser = (userId: string, user: Partial<User>, onError?: (response: ResponseData<any>) => void): Promise<User> => this.requestApi({
        url: `admin/user/${userId}`,
        method: 'PUT',
        data: {
            name: user.login,
            phone: user.phone,
            weixinName: user.name,
            imgUrl: user.avatar,
            pwd: user.password,
            roleIds: user.permissions?.filter(v => v),
        }
    }).then(r => ({...responseTransfer(r, userResponseTransfer, onError), ...user}));

    createUser = (user: Partial<User>): Promise<User> => this.requestApi({
        url: `admin/user`,
        method: 'POST',
        data: {
            name: user.login,
            phone: user.phone,
            imgUrl: user.avatar,
            pwd: user.password,
            weixinName: user.name,
            roleIds: user.permissions?.filter(v => v),
        }
    }).then(r => responseTransfer(r, userResponseTransfer));


    updateGood = (goodId: string, good: Partial<Good>): Promise<Good> => this.requestApi({
        url: `admin/products/${goodId}`,
        method: 'PUT',
        data: {
            horizontalImgUrl: good.cover,
            imgUrl: good.icon,
            name: good.name,
            productNumber: good.number
        }
    }).then(r => responseTransfer(r, _ => ({
        ...good,
        id: goodId,
    })));

    createGood = (good: Partial<Good>): Promise<Good> => this.requestApi({
        url: `admin/products`,
        method: 'POST',
        data: {
            horizontalImgUrl: good.cover,
            imgUrl: good.icon,
            name: good.name,
            productNumber: good.number
        }
    }).then(r => responseTransfer(r, goodResponseTransferByAdminProduct));

    removeGood = (goodId: string) => this.requestApi({
        url: `admin/products/${goodId}`,
        method: 'DELETE'
    }).then(r => responseTransfer(r, item => item));

    updateGoodStatus = (goodId: string, status: number) => this.requestApi({
        url: `admin/products/${goodId}/status`,
        method: 'PUT',
        data: {status: status}
    }).then(r => responseTransfer(r, item => item));

    updateCard = (cardId: string, card: Partial<Card> & { companyTagId?: number|string }) => this.requestApi({
        url: `admin/yearCard`,
        method: 'PUT',
        data: {...(cartToCommit(card)), id: cardId, userId: undefined}
    }).then(r => responseTransfer(r, cardResponseTransfer));

    createCard = (card: Partial<Card>): Promise<Card> => this.requestApi({
        url: `admin/yearCard`,
        method: 'POST',
        data: cartToCommit(card)
    }).then(r => responseTransfer(r, cardResponseTransfer));


    setCardItem = (cardId: string, items: {
        goodId: string;
        count?: number;
        consume?: number;
    }[]) => this.requestApi({
        url: `cards/${cardId}/actions/setItems`,
        method: 'POST',
        data: items
    });

    updateCardItem = (cardId: string, items: {
        goodId: string;
        count?: number;
        consume?: number;
    }[]) => this.requestApi({
        url: `cards/${cardId}/actions/updateItems`,
        method: 'POST',
        data: items
    });

    listMessage = (options?: { page?: RequestPageInfo; }) => this.requestApi({
        url: `admin/messages${requestParams({...(pageTransfer(options?.page)) })}`,
        method: 'GET',
        headers: createPageHeader(options)
    }).then(r => listResponseTransfer(r, v => v as MessageResponse));

    markMessageRead = (messageId: string) => this.requestApi({
        url: `admin/messages/${messageId}`,
        method: 'PUT'
    }).then(r => responseTransfer(r, v => v as MessageResponse));

    listTemplateCard = (options?: {
        page?: RequestPageInfo;
        search?: string;
        fillItemCount?: boolean;
        fillUser?: boolean;
        fillAgentUser?: boolean;
        fillCurrency?: boolean;
    }): Promise<PageList<Card>> => this.requestApi<ResponseData<CardResponse[]>>({
        url: `admin/yearCardTemp${requestParams({...(pageTransfer(options?.page)) })}`,
        method: 'GET',
        headers: createPageHeader(options)
    }).then(r => responseTransfer(r, v => v.map(cardResponseTransfer)))
        .then(v => ({items: v, count: v.length, offset: 0, size: v.length}));

    listCard = (options?: {
        page?: RequestPageInfo;
        search?: string;
        status?: 0|1
        fillItemCount?: boolean;
        fillUser?: boolean;
        fillAgentUser?: boolean;
        fillCurrency?: boolean;
    }): Promise<PageList<Card>> => this.requestApi<ResponseData<RecordList<CardResponse>>>({
        url: `admin/user/yearCardList${requestParams({...(pageTransfer(options?.page)), ...options })}`,
        method: 'GET',
        headers: createPageHeader(options)
    }).then(r => listResponseTransfer(r, cardResponseTransfer));

    listUserCard = (userId: string, options?: {
        page?: RequestPageInfo;
        fillCurrency?: boolean;
    }): Promise<PageList<Card>> => this.requestApi<ResponseData<CardResponse[]>>({
        url: `/admin/user/${userId}/yearCard${requestParams({...(pageTransfer(options?.page))})}`,
        method: 'GET',
        headers: createPageHeader(options)
    }).then(r => responseTransfer(r, v => ({
        items: v.map(cardResponseTransfer),
        offset: 0,
        size: v.length,
        count: v.length
    })));

    listCardItem = (cardId: string, options?: {
        page?: RequestPageInfo,
        fillGood?: boolean
    }) => this.requestListApi<CardItem>({
        url: `cards/${cardId}/items${requestParams(options)}`,
        method: 'GET',
        headers: createPageHeader(options)
    });

    listGood = (options?: {
        page?: RequestPageInfo,
        search?: string;
        allProduct?: boolean;
    }): Promise<PageList<Good>> => this.requestApi<ResponseData<RecordList<ProductResponse>>>({
        url: `admin/products${requestParams({...(pageTransfer(options?.page)),
            productName: options?.search,
            ...options
        })}`,
        method: 'GET',
        headers: createPageHeader(options)
    }).then(r => listResponseTransfer(r, d => ({
        id: d.id.toString(),
        name: d.name,
        number: d.productNumber,
        description: d.content,
        icon: d.imgUrl,
        cover: d.horizontalImgUrl,
        type: d.productType?.toString(),
        status: d.isDelete ? 0 : 1
    })));

    listAfterSaleTags = (options?: {type: number}) => this.requestApi({
        url: `admin/afterSaleTags${requestParams(options)}`,
        method: 'GET',
    }).then(r => responseTransfer(r, v => v as {
        id: number;
        name: string;
    }[]));

    listOrder = (options?: {
        page?: RequestPageInfo,
        search?: string;
        startAt?: string;
        endAt?: string;
        fillUser?: boolean;
        fillCurrency?: boolean;
        fillDelivery?: boolean;
    }): Promise<PageList<Order>> => this.requestApi<ResponseData<RecordList<OrderResponse>>>({
        url: `admin/orders${requestParams({...(pageTransfer(options?.page)), ...options})}`,
        method: 'GET',
        headers: createPageHeader(options)
    }).then(r => listResponseTransfer(r, orderResponseTransfer));

    listOrderItem = (orderNumber: string, options?: {
        page?: RequestPageInfo,
        fillCurrency?: boolean;
        fillCard?: boolean;
        fillGood?: boolean;
    }) => this.requestListApi<OrderItem>({
        url: `orders/${orderNumber}/items${requestParams(options)}`,
        method: 'GET',
        headers: createPageHeader(options)
    });

    listOrderOperationLog = (orderNumber: string): Promise<PageList<Log>> => this.requestApi<ResponseData<LogResponse[]>>({
        url: `admin/orders/${orderNumber}/operationLogs`,
        method: 'GET'
    }).then(r => responseTransfer(r, v => ({
        items: v.map((e, index) => ({id: index.toString(),
            createTime: timeResponseTransfer(e.operationAt),
            content: e.content,
            summary: e.remark,
            type: e.type.toString()})),
        count: v.length,
        offset: 0,
        size: v.length
    })));

    updateOrderSummary = (id: string, summary: string) => this.requestApi({
        url: `admin/orders/sellerRemark`,
        method: 'PUT',
        data: {
            id: id,
            remark: summary
        }
    }).then(r => responseTransfer(r, item => item));

    addUserPermission = (userId: string, permission: string|string[]) => this.requestApi({
        url: `users/${userId}/actions/addPermission`,
        method: 'POST',
        data: Array.isArray(permission) ? permission : [permission]
    });

    removeUserPermission = (userId: string, permission: string|string[]) => this.requestApi({
        url: `users/${userId}/actions/removePermission`,
        method: 'POST',
        data: Array.isArray(permission) ? permission : [permission]
    });

    changeUserPassword = (userId: string, password: string) => this.requestApi({
        url: `users/${userId}/actions/changePassword`,
        method: 'POST',
        data: {newPassword: password}
    });

    disablePlatformUser = (userId: string) => this.requestApi<User>({
        url: `users/${userId}/actions/disable`,
        method: 'POST',
    });

    enableUser = (userId: string) => this.requestApi<User>({
        url: `users/${userId}/actions/enable`,
        method: 'POST',
    });

    removeUser = (userId: string) => this.requestApi({
        url: `users/${userId}`,
        method: 'DELETE',
    });

    getPhoneByOrder = (orderNumber: string) => this.requestApi({
        url: `/admin/secretPhone?clientType=2&type=1&domainId=${orderNumber}`,
        method: 'GET',
    }).then(r => responseTransfer(r, v => v as UserPhone));

    getPhoneByCard = (cardNumber: string) => this.requestApi({
        url: `/admin/secretPhone?clientType=1&type=2&domainId=${cardNumber}`,
        method: 'GET',
    }).then(r => responseTransfer(r, v => v as UserPhone));

    getImageVerifyCode = (key?: string): Promise<VerifyCode> => this.requestApi<{
        code: number;
        data: {
            codeKey: string;
            img: string;
        };
        message: string;
    }>({
        url: `admin/validCode`,
        method: 'GET',
    }).then(v => ({
        key: v.data.codeKey,
        image: v.data.img
    }));

    cardStatus = (id: string, status: 1|2|3) => this.requestApi({
        url: `admin/yearCard/status`,
        method: 'PUT',
        data: {
            id: id,
            status: status
        }
    }).then(r => responseTransfer(r, v => v));

    cardExport = (options: {
        page?: RequestPageInfo,
        endTime: string,
        startTime?: string,
    }) => this.requestApi({
        url: `admin/yearCard/export${requestParams({
            ...(pageTransfer(options?.page ?? { offset: 0 })),
            ...options
        })}`,
        method: 'POST'
    }).then(r => listResponseTransfer(r, (v: string) => v));

    cardExportBySearch = (options: {
        page?: RequestPageInfo,
        search: {
            createUserName?: string,
            createUserPhone?: string,
            userName?: string,
            userPhone?: string,
            yearCardOrderNo?: string,
            stokeWarningStatus?: string,
            filterEmptyCard?: boolean
        },
    }) => this.requestApi({
        url: `admin/yearCard/exportBySearch${requestParams({
            ...(pageTransfer(options?.page ?? { offset: 0 })),
            ...options.search
        })}`,
        method: 'POST'
    }).then(r => listResponseTransfer(r, (v: string) => v));

    orderExport = (options: {
        page?: RequestPageInfo,
        endTime: string,
        startTime?: string
    }) => this.requestApi({
        url: `admin/order/export${requestParams({
            ...(pageTransfer(options?.page ?? { offset: 0 })),
            ...options
        })}`,
        method: 'POST'
    }).then(r => listResponseTransfer(r, (v: string) => v));
    /*
        orderExport = (options: {
        endTime: string,
        startTime?: string
    }) => this.requestApi({
        url: `admin/order/export${requestParams(options)}`,
        method: 'POST'
    }).then(r => responseTransfer(r, (v: string) => v));
     */

    orderCheck = (options: {
        id: string,
        reason: string,
        type: number,
    }) => this.requestApi({
        url: `admin/orders/check`,
        method: 'PUT',
        data: options
    }).then(r => responseTransfer(r, v => v));

    orderRefundCheck = (options: {
        id: string,
        reason: string,
        type: number,
    }) => this.requestApi({
        url: `admin/orders/refundCheck`,
        method: 'PUT',
        data: options
    }).then(r => responseTransfer(r, v => v));

    orderDelivery = (options: {
        id: string,
        platform: string,
        platformNumber: number,
        number: string
    }) => this.requestApi({
        url: `admin/orders/deliver`,
        method: 'PUT',
        data: {
            id: options.id,
            companyName: options.platform,
            externalBillNum: options.number,
            wayBillNum: options.platformNumber,
        }
    }).then(r => responseTransfer(r, v => v));

    deliveryTrackInfo = (options: {
        number: string;
        phone?: string;
    }) => this.requestApi({
        url: `admin/trackingInfo${requestParams({
            wayBillNumber: options.number,
            phone: options.phone
        })}`,
        method: 'GET',
    }).then(r => responseTransfer(r, v => (v as DeliveryResponse).data));

    trackInfo = (orderId: string) => this.requestApi({
        url: `admin/orders/${orderId}/trackingInfos`,
        method: 'GET',
    }).then(r => responseTransfer(r, v => v as TrackResponse[]));

    decrypt = (content: string) => this.requestApi({
        url: `admin/decrypt`,
        method: 'POST',
        data: { params: content }
    }).then(r => responseTransfer(r, v => v as string));



    addRemindTime = (remindTime: string, remindContent: string, userYearCardId: string) => this.requestApi({
        url: '/admin/yearCard/remind',
        method: 'POST',
        data: {
            remindTime: remindTime,
            remindContent: remindContent,
            userYearCardId: userYearCardId,
        }
    }).then(r => responseTransfer(r, v => v));


}

function requestParams(params?: { [key: string]: any | undefined }, prefix: string = '?') {
    if (!params) return '';
    const content = Object.keys(params)
        .map(k => {
            const v = params[k];
            const checkV = (ok: any, ov: any) => {
                if (typeof ov == 'object') {
                    const { k, v } = ov;
                    return typeof k == 'string' && v && typeof v != 'object' ? `${k}=${encodeURIComponent(v)}` : undefined;
                } else if (ov !== undefined) return `${ok}=${encodeURIComponent(v)}`;
            }
            return Array.isArray(v) ? v.map(e => checkV(k, e)).filter(e => e !== undefined).join('&') : checkV(k, v);
        }).filter(v => v).join('&');
    return content.length > 0 ? `${prefix}${content}` : '';
}
function joinUrl(baseUrl: string, ...paths: string[]): string {
    if (baseUrl.length > 0) {
        if (baseUrl.indexOf('://') < 0)
            baseUrl = 'http://' + baseUrl;
        if (baseUrl.endsWith('/'))
            baseUrl = baseUrl.substring(0, baseUrl.length - 1);
    }
    if (paths != null) {
        paths.forEach(v => baseUrl += v.startsWith('/') ? v : '/' + v);
    }
    return baseUrl.startsWith('/') ? baseUrl.substring(1) : baseUrl;
}

function createPageHeader(options?: {page?: RequestPageInfo}) {
    const defaultPageSize = 20
    if (options?.page) {
        const {offset, size} = options.page;
        return { offset: (offset ?? 0).toString(), size: (size ?? defaultPageSize).toString() }
    }
    return {size: defaultPageSize.toString()};
}

function createHeader(headers: { [key: string]: any | undefined }) {
    const t: {[key: string]: string} = {};
    Object.keys(headers).forEach(k => {
        const v = headers[k];
        if (v !== undefined) t[k] = v.toString();
    });
    return t;
}

function cartToCommit(card: Partial<Card>) {
    return {
        boughtAt: card.createTime,
        lastTimes: card.deliveryCount,
        createUserId: (card.agentUser?.id?.length ?? 0) > 0 ? card.agentUser?.id : undefined,
        userId: card.user?.id,
        orderNo: card.externalNumber,
        platform: card.externalPlatform,
        yearCardId: card.card?.id,
        companyTagId: card.company?.id,
        phone: card.user?.id ? undefined : card.user?.phone,
        wxNickName: card.user?.id ? undefined : card.user?.name,
        money: card.value ? parseFloat(card.value) * 100 : undefined,
        productList: card.items?.map(v => (v.good?.status ?? -1) >= 0 ? {
            productId: v.good?.id,
            productType: 1,
            count: v.count
        }: {
            content: card.birthdayPresent ?? card.card?.birthdayPresent,
            productId: v.good?.id,
            productType: 2,
            count: 1
        })
    };
}

function pageTransfer(page?: RequestPageInfo) {
    if (!page) return undefined;
    const offset = page.offset ?? 0;
    const size = page.size;
    const pageNo = size ? Math.ceil((offset + size - 1) / size) : 1;
    return {
        pageNo: pageNo.toString(),
        pageSize: size?.toString()
    };
}

function listResponseTransfer<T, R>(response: ResponseData<RecordList<T>>, convert: (data: T) => R): PageList<R> {
    return responseTransfer(response, d => ({
        offset: d.pageNo ? (d.pageNo - 1) * d.pageSize : 0,
        size: d.pageSize,
        count: d.total,
        items: Array.isArray(d.records) ? d.records.map(convert) : [convert(d.records)]
    }));
}

export default Repository;
