import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {UserSessionProvider} from "./repo/context";
import {ConfigProvider} from "antd";
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <UserSessionProvider>
          <ConfigProvider theme={{
              token: {colorPrimary: '#1DA57A'}
          }}><App /></ConfigProvider>
      </UserSessionProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
