import {
    Input,
    Button,
    Table,
    Typography,
    DatePicker, Modal, Space, Drawer,
    Descriptions
} from "antd";
import {useUserSessionContext} from "@/repo/context";
import {CSSProperties, ReactNode, useEffect, useState} from "react";
import {SearchOutlined} from "@ant-design/icons";
import {InfoLine, Label} from "@/component/table";
import CommonTable from "@/component/table/common-table";
import {PageList, RequestPageInfo} from "@/repo/model/common";
import {LogResponse} from "@/repo/response/log";
import moment from "dayjs";
import Repository from "@/repo/repository";
import {CardResponse} from "@/repo/response/card";

const orderFilters = ['已创建', '已审核', '已发货', '完成', '关闭', '售后'];

export default () => {
    const [datasource, setDatasource] = useState<PageList<LogResponse>>();
    const [loading, setLoading] = useState(false);
    const {repo} = useUserSessionContext();
    const [search, setSearch] = useState<{
        orderNo?: string,
        phone?: string,
        type?: number,
        startAt?: string,
        endAt?: string
    }>();
    const [requestPage, setRequestPage] = useState<RequestPageInfo>({offset: 0});
    const [detailLog, setDetailLog] = useState<LogResponse>();

    const refresh = () => setRequestPage(p => ({...p, offset: 0}));

    useEffect(() => {
        if (repo) {
            setLoading(true);
            repo?.listOperationLog({
                page: requestPage,
                ...search
            }).then(setDatasource)
                .catch(e => Modal.error({content: e}))
                .finally(() => setLoading(false));
        }
    }, [repo, requestPage]);
    return <>
        <CommonTable
            onPageChange={(page, pageSize) => setRequestPage({...requestPage, offset: (page - 1) * pageSize, size: pageSize})}
            rightAction={<>
                <DatePicker.RangePicker onChange={v => {
                    let startAt: string|undefined = undefined;
                    let endAt: string|undefined = undefined;
                    if (v) {
                        const [s, e] = v;
                        startAt = s?.toISOString();
                        endAt = e?.toISOString();
                    }
                    setSearch(p => ({
                        ...p,
                        startAt: startAt,
                        endAt: endAt
                    }));
                    setRequestPage({offset: 0});
                }} />
                <Input placeholder='订单号'
                       allowClear
                       value={search?.orderNo}
                       onChange={e => setSearch(p => ({...p, orderNo:  e.target.value}))}/>
                <Input placeholder='手机号'
                       inputMode="tel"
                       allowClear
                       value={search?.phone}
                       onChange={e => setSearch(p => ({...p, phone:  e.target.value}))}/>
                <Button icon={<SearchOutlined/>} onClick={refresh}/>
            </>}
            loading={loading}
            {...datasource}>
            <Table.Column
                title={<Typography.Text ellipsis>时间</Typography.Text>}
                render={(e: LogResponse) =><Label>{formatTime(e.operationAt?.toString()) ?? '无用户'}</Label>}
            />
            <Table.Column
                title={<Typography.Text ellipsis>操作</Typography.Text>}
                render={(e: LogResponse) => <Label>{e.content ?? '无信息'}</Label>}
            />
            <Table.Column
                title={<Typography.Text ellipsis>用户</Typography.Text>}
                render={(e: LogResponse) =><Label>{e.operationUserName ?? '无用户'}</Label>}
            />
            <Table.Column
                align='right'
                title={<Typography.Text ellipsis>操作</Typography.Text>}
                render={(e: LogResponse) => <Typography.Link onClick={() => setDetailLog(e)}>详情</Typography.Link>}
            />
        </CommonTable>
        {repo ? <Drawer placement='right'
                        width={600}
                        title={detailLog ? `${formatTime(detailLog.operationAt.toString())} - ${detailLog.operationUserName}` : undefined}
                destroyOnClose
                onClose={() => setDetailLog(undefined)}
                open={detailLog !== undefined}>
            <LogDetail log={detailLog} repo={repo}/>
        </Drawer> : null}
    </>
};

const LogDetail = (props: {log?: LogResponse, repo: Repository}) => {
    const {log, repo} = props;
    const [displayLog, setDisplayLog] = useState<LogResponse>();
    useEffect(() => {
        if (log) {
            setDisplayLog(log);
            repo.getOperationLogDetail(log.id).then(setDisplayLog);
        }
    }, [repo, log?.id]);
    /*
    <Descriptions.Item label='操作类型'>
                {typeText(displayLog)}
            </Descriptions.Item>
     */
    return <Space direction='vertical'>
        <Descriptions column={1}>
            <Descriptions.Item label='操作详情'>
                {displayLog?.content}
            </Descriptions.Item>
        </Descriptions>
        <Space direction='horizontal' align='start' size={20}>
            {displayLog?.newYearCard
                ? <CardContent
                    title='变动后年卡'
                    card={displayLog.newYearCard}/> : null}
            {displayLog?.preYearCard
                ? <CardContent
                    title='变动前年卡'
                    card={displayLog.preYearCard}/> : null}
        </Space>
    </Space>;
};

const CardContent = (props: {
    title: ReactNode,
    card: CardResponse,
    style?: CSSProperties,
}) => {
    const {title, card, style} = props;
    return <Descriptions
        size='small'
        style={style}
        column={1} title={title} bordered>
        <Descriptions.Item label='ID'>{card.id} ({card.yearCardId})</Descriptions.Item>
        {card.productList?.sort((a, b) => a.id - b.id)
            .map((e, index) => <Descriptions.Item
            key={index}
            label={e.name}>
                <Typography.Text
                    ellipsis
                    type='danger'>{e.count}</Typography.Text>
            </Descriptions.Item>)}
    </Descriptions>;
}

const formatTime = (str?: string) => {
    if (!str) return undefined;
    const date = new Date(str);
    return moment(date).format(`YYYY/MM/DD HH:MM`);
}

const typeText = (log?: LogResponse) => {
   switch (log?.type) {
       case 0: return '创建年卡';
       case 1: return '下单扣除余额';
       case 2: return '退单添加余额';
       case 3: return '后台修改余额';
       case 4: return '编辑年卡';
       case 5: return '订单取消添加余额';
       case 6: return '删除年卡';
       default: return '其他';
   }
}