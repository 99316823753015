import {InfoLine, Label} from "@/component/table/index";
import {Avatar, Space, Image} from "antd";
import moment from "dayjs";
import {useState} from "react";

export default (props: {
    id?: string;
    icon?: string;
    cover?: string;
    number?: string;
    description?: string;
    name?: string;
    createTime?: string;
    coverName?: string;
}) => {
    const {id, icon, cover, number, description, name} = props;
    const coverName = props.coverName;
    const [previewImage, setPreviewImage] = useState<string>();
    const createTime = props.createTime ? new Date(props.createTime) : undefined;
    const timeString = createTime
        ? moment(createTime).format(
            createTime.getFullYear() === new Date().getFullYear()
                ? "MM/DD HH:mm" : "YYYY/MM/DD")
        : undefined;
    return <><Space direction="vertical" size={4}>
        <Space size={14} align="center">
            <Avatar size={60}
                    shape="square"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'}}
                    icon={icon || cover ? <Image
                        src={icon ?? cover}/> : null}>
                {name?.substring(0, 1).toUpperCase()}
            </Avatar>
            <div className='column'>
                <InfoLine>{name}</InfoLine>
                {number ? <InfoLine label={`编号: ${number}`}/> : null}
                {description ? <InfoLine label={description}/> : null}
                <Space>
                    {coverName ? <Label>
                        {cover ? <a onClick={() => setPreviewImage(cover)}>
                            {coverName}
                        </a> : `未设置${coverName}`}
                    </Label> : null}
                    <Label>ID: {id}</Label>
                </Space>
            </div>
        </Space>
        {timeString ? <Label>{timeString} 创建</Label> : null}
    </Space>
        <Image
            style={{ display: 'none' }}
            preview={{
                visible: previewImage !== undefined,
                src: previewImage ?? '',
                onVisibleChange: value => value ? undefined : setPreviewImage(undefined),
            }}
        />
    </>;
}
