import {InfoLine, Label} from "@/component/table/index";
import {Space, Avatar, Image} from "antd";
import moment from "dayjs";
import {CSSProperties, useEffect, useState} from "react";
import {EyeInvisibleOutlined, LoadingOutlined} from "@ant-design/icons";
import Repository from "@/repo/repository";

export default (props: {
    id?: string;
    avatar?: string;
    name?: string;
    login?: string;
    phone?: string;
    showPhone?: boolean;
    showLogin?: boolean;
    showId?: boolean;
    createTime?: string;
    encrypt?: {
        name?: string;
        phone?: string;
    };
    style?: CSSProperties;
    repo?: Repository;
}) => {
    const {id,
        avatar,
        login,
        style,
        showId,
        showPhone,
        showLogin,
        repo,
    } = props;

    const createTime = props.createTime ? new Date(props.createTime) : undefined;
    const timeString = createTime
        ? moment(createTime).format(
            createTime.getFullYear() === new Date().getFullYear()
                ? "MM/DD HH:mm" : "YYYY/MM/DD")
        : undefined;
    const [loading, setLoading] = useState(false);
    const [display, setDisplay] = useState<{
        name?: string;
        phone?: string;
        encrypt?: {
            name?: string;
            phone?: string;
        };
    }>({});
    const {name, phone, encrypt} = display;
    useEffect(() => setDisplay(props), []);
    const displayName = (name?.length ?? 0) > 0 ? name
        : showLogin && (login?.length ?? 0) > 0 ? login
            : showPhone && (phone?.length ?? 0 > 0) ? phone :  undefined;
    return <Space direction="vertical" size={4} style={style}>
        <Space size={14} align="center">
            <Avatar size="large" icon={avatar ? <Image src={avatar}/> : null}>
                {(displayName?.trim() ?? '-').substring(0, 1).toUpperCase()}
            </Avatar>
            <Space direction='vertical' size={0}>
                {name || !showLogin && !showPhone
                    ? <InfoLine
                        action={encrypt && repo ? async () => {
                            setLoading(true);
                            const name = encrypt?.name ? await repo?.decrypt(encrypt.name) : undefined;
                            const phone = encrypt?.phone ? await repo?.decrypt(encrypt.phone) : undefined;
                            setDisplay(p => ({
                                name: !name?.length ? p.name : name,
                                phone: !phone?.length ? p.phone : phone }));
                            setLoading(false);
                        } : undefined}
                        actionTooltip='显示敏感数据'
                        actionIcon={encrypt && repo ? loading ? <LoadingOutlined /> : <EyeInvisibleOutlined/> : undefined}
                        contentPlaceHolder='昵称'>{name && name.length > 0 ? name : undefined}</InfoLine> : null}
                {showId && id ? <Label>ID: {id}</Label> : null}
                {showLogin && login ? <Label>{login}</Label> : null}
                {showPhone && phone ? <Label>{phone}</Label> : null}
            </Space>
        </Space>
        {timeString ? <Label>{timeString} 创建</Label> : null}
    </Space>;
}