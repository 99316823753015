import { useState } from "react";
import { UserSession } from "./model/user";
import constate from 'constate';
import Repository from "./repository";

const useUserSession = (value: { repo?: Repository, userSession?: UserSession }) => {
    const [userSession, setUserSession] = useState(value.userSession);
    const [repo, setRepo] = useState(value.repo);
    const updateRepo = (nr?: Repository) => setRepo(nr);
    const updateUserSession = (nu?: UserSession) => {
        if (repo) repo.setSession({token: nu?.token})
        setUserSession(nu);
    }
    const hasPermission = (p: string|string[]) => {
        const permissions = userSession?.user.permissions;
        return permissions && permissions.length > 0
            && (typeof p === 'string' ? permissions.indexOf(p) >= 0
                : p.findIndex(v => permissions.indexOf(v) >= 0) >= 0)
    };
    return { repo, userSession, updateRepo, updateUserSession, hasPermission };
}

export const [UserSessionProvider, useUserSessionContext] = constate(useUserSession);
