import {Button, Form, Input, Modal} from "antd";
import {passwordRegex} from "@/util/string-utils";
import {useState} from "react";

const ChangePasswordDialog = (props: {
    open: boolean,
    onClose: () => void,
    onChange: (password: string, newPassword: string) => Promise<any>
}) => {
    const {open, onClose, onChange} = props;
    const [committing, setCommitting] = useState(false);
    return <Modal open={open}
                  title='修改密码'
                  destroyOnClose
                  onCancel={onClose}
                  footer={null}
                  width={300}>
        <Form labelCol={{span: 8}} wrapperCol={{span: 16}}
              onFinish={r => {
                  setCommitting(true);
                  onChange(r.password, r.newPassword)
                      .then(onClose)
                      .catch(e => Modal.error({content: e}))
                      .finally(() => setCommitting(false));
              }}
              validateMessages={{
                  whitespace: '输入内容',
                  required: '补齐需要的内容',
                  pattern: { mismatch: '密码不符合要求，至少8-16个字符，至少1个大写字母，1个小写字母和1个数字'}
              }}
              style={{marginTop: 20}}>
            <Form.Item label='当前密码' name="password" required rules={[{required: true}]}>
                <Input.Password placeholder='输入当前密码'/>
            </Form.Item>
            <Form.Item label='新密码' name="newPassword" required rules={[{
                required: true,
                pattern: passwordRegex
            }]}>
                <Input.Password placeholder='输入新密码'/>
            </Form.Item>
            <Form.Item label='重复密码' name="confirmPassword"
                       dependencies={['newPassword']}
                       required
                       rules={[{required: true}, form => ({
                           validator: (_, v) => {
                               const np = form.getFieldValue("newPassword");
                               return (v?.length ?? 0) > 0 && (np?.length ?? 0) > 0 && v !== np
                                   ? Promise.reject('两次密码输入不一致')
                                   : Promise.resolve();
                           }
                       })]}>
                <Input.Password placeholder='重复输入新密码'/>
            </Form.Item>
            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                <Button type="primary"
                        loading={committing}
                        htmlType="submit">提交</Button>
                <Button onClick={onClose} style={{marginRight: 10}}>取消</Button>
            </div>
        </Form>
    </Modal>;
}

export default ChangePasswordDialog;