import {
    Modal,
    Form, Space, FormInstance, Divider,
} from "antd";
import React, {CSSProperties, ReactNode, useEffect, useState} from "react";
import {FormLayout, useForm} from "antd/lib/form/Form";
import {useUserSessionContext} from "@/repo/context";

function ContentEditor<T>(props: {
    idGetter?: (item: T) => string,
    formLayout?: FormLayout;
    titleBuilder: (isNew: boolean) => ReactNode,
    contentBuilder?: (isNew: boolean, form: FormInstance) => ReactNode,
    onCreateItem?: (item: Partial<T>) => Promise<T>,
    onUpdateItem?: (item: Partial<T>) => Promise<T>,
    item?: T,
    onInit?: (item: T) => {[key: string]: any}|Promise<{[key: string]:any}>,
    onComplete: (item?: T) => void,
    children?: ReactNode,
    childrenAlign?: 'start' | 'end' | 'center' | 'baseline';
    childrenSpace?: number,
    formWidth?: number|string,
    onSubmit?: (form: FormInstance) => void,
    okText?: ReactNode,
    footer?: React.ReactNode;
    cancelText?: ReactNode,
    form?: FormInstance
    bodyStyle?: CSSProperties
}) {
    const {item,
        okText,
        cancelText,
        titleBuilder,
        contentBuilder,
        formLayout,
        children,
        childrenAlign,
        childrenSpace,
        formWidth,
        onInit,
        onSubmit,
        footer,
        bodyStyle,
        onComplete,
        onCreateItem,
        onUpdateItem} = props;
    const form = props.form ?? useForm()[0];
    const {repo} = useUserSessionContext();
    const [confirming, setConfirming] = useState(false);
    const idGetter = props.idGetter ?? ((item) => (item as {id?: string}).id);
    useEffect(() => {
        if (item && form) {
            const r = onInit ? onInit(item) : item;
            if (r instanceof Promise) {
                setConfirming(true);
                r.then(form.setFieldsValue).finally(() => setConfirming(false));
            } else form.setFieldsValue(r);
        }
    }, [form, item]);
    const itemId = (item && idGetter ? idGetter(item) : null) ?? '';
    const isNew = itemId.length === 0;
    const formContent = <Form
        style={{width: formWidth ?? 380}}
        onFinish={repo ? v => {
            const method = isNew ? onCreateItem : onUpdateItem;
            method ? method(v).then(onComplete)
                    .catch(e => Modal.info({
                        title: `${isNew ? '创建' : '修改'}错误`,
                        content: e ? e.toString() : undefined
                    }))
                    .finally(() => setConfirming(false))
                : setConfirming(false);
        } : undefined}
        onFinishFailed={() => setConfirming(false)}
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: formLayout === 'vertical' ? 24 : 18 }}
        validateMessages={{
            whitespace: '输入内容',
            required: '补齐需要的内容'
        }}
        layout={formLayout ?? "horizontal"}>
        {contentBuilder ? contentBuilder(isNew, form) : undefined}
    </Form>;
    return <Modal open={item !== undefined}
                  centered
                  width='auto'
                  title={titleBuilder(isNew)}
                  confirmLoading={confirming}
                  okText={okText}
                  cancelText={cancelText}
                  onOk={() => {
                      setConfirming(true);
                      onSubmit ? onSubmit(form) : form.submit();
                  }}
                  footer={footer}
                  bodyStyle={bodyStyle ?? { padding: '20px 0' }}
                  closable={!confirming}
                  cancelButtonProps={confirming ? {disabled: true} : undefined}
                  onCancel={confirming ? undefined : () => {
                      onComplete(undefined)
                      form.resetFields();
                  }}>
        {children ? <Space align={childrenAlign} size={childrenSpace}>
            {formContent}
            {children}
        </Space> : formContent}
    </Modal>;
}

export default ContentEditor;