import {Table, Typography, Row, Steps, Col, Space, Descriptions, Popover, Button, Empty, Spin, Segmented} from "antd";
import {Order, OrderItem} from "@/repo/model/order";
import {InfoLine} from "@/component/table";
import {GoodItem} from "@/view/card/good-picker";
import {Log} from "@/repo/model/log";
import Repository from "@/repo/repository";
import {useEffect, useState} from "react";
import moment from "dayjs";
import {Delivery} from "@/repo/model/delivery";
import {TrackResponse} from "@/repo/response/deliviery";

export default (props: {
    repo: Repository,
    order: Order,
    showLog?: boolean,
    showStatus?: boolean,
}) => {

    const {repo, order, showLog, showStatus} = props;
    const [orderItems, setOrderItems] = useState<OrderItem[]>();
    const [logs, setLogs] = useState<Log[]>();
    useEffect(() => {
        setOrderItems(order.items);
        if (!order.items) repo.listOrderItem(order.number, {
            fillGood: true,
            fillCard: true,
            fillCurrency: true})
            .then(v => setOrderItems(v.items));
    }, [order.number, repo]);
    useEffect(() => {
        setLogs(undefined);
        if (showLog) repo.listOrderOperationLog(order.number).then(v => setLogs(v.items));
    }, [order.number, showLog, repo]);

    const status = order.status ?? 0;
    const processStatus = order.processStatus;
    const delivery = order.deliveries
    && order.deliveries.length > 0
        ? order.deliveries[0] : undefined;
    const orderSteps = [
        <Steps.Step
            key={0}
            title={
            <Space size={4}>
                下单
                {status === 2 ? <a>待审核</a> : null}
            </Space>
        } subTitle={formatTime(order.createTime)}/>,
        <Steps.Step
            key={1}
            title={
            <Space size={4}>
                发货
                {delivery ? <Popover
                    placement="top"
                    trigger='click'
                    content={<DeliveryInfo
                        order={order}
                        delivery={delivery}
                        repo={repo}/>}>
                    <a>{delivery.externalPlatform ?? delivery.externalNumber}</a>
                </Popover> : null}
            </Space>
        } subTitle={formatTime(delivery?.createTime)}/>,
        <Steps.Step
            key={2}
            title='订单完成' subTitle={formatTime(order.finishTime)}/>
    ];
    const orderStepIndex = status < 3 ? 0 : status < 5 ? 1 : 2;
    const content = <>{!showStatus ? undefined : processStatus ? <Steps progressDot style={{marginBottom: 10}} size='small'
                                                                        current={orderStepIndex + (processStatus < 2 ? 1 : 2)}>
        {orderSteps.slice(0, orderStepIndex + 1)}
        <Steps.Step title='申请售后'/>
        <Steps.Step title='售后完成' subTitle={
            processStatus && processStatus > 1 ? <Typography.Text style={{color: processStatus === 2 ? 'red' : 'green'}}>
                {processStatus === 2 ? '拒绝退货' : '同意退货'}
            </Typography.Text> : null
        }/>
    </Steps> : <Steps progressDot style={{marginBottom: 10}} size='small' current={orderStepIndex}>
        {orderSteps}
    </Steps>}
        <OrderItemTable items={orderItems}/>
    </>;

    return showLog ? <Row style={{margin: 10}} gutter={20}>
        <Col span={16}>
            {content}
        </Col>
        <Col span={8}>
            <Space direction='vertical' size={0}>
                {logs?.map(l => <Typography.Text type='secondary' key={l.id} style={{fontSize: 10}}>
                    {formatTime(l.createTime)}
                    <span style={{marginLeft: 6, color: 'green', opacity: 0.6}}>{l.content}</span>
                </Typography.Text>)}
            </Space>
        </Col>
    </Row> : content;
}

const OrderItemTable = (props: {items?: OrderItem[]}) => <Table
    size='small'
    pagination={false}
    rowKey='good'
    bordered
    loading={props.items === undefined} dataSource={props.items}>
    <Table.Column
        title={<Typography.Text ellipsis>商品</Typography.Text>}
        render={(e: OrderItem) => <InfoLine contentPlaceHolder='无价格'>
            {e.good ? <GoodItem good={e.good}/> : null}
        </InfoLine>}
    />
    <Table.Column
        align='right'
        title={<Typography.Text ellipsis>单价</Typography.Text>}
        render={(e: OrderItem) => <InfoLine contentPlaceHolder='无价格'>
            {e.value ? `${e.currency?.symbol}${e.value}` : null}
        </InfoLine>}
    />
    <Table.Column
        align='right'
        title={<Typography.Text ellipsis>数量</Typography.Text>}
        render={(e: OrderItem) => <InfoLine contentPlaceHolder='无数量'>
            {(e.count ?? 0 > 0) ? e.count : null}
        </InfoLine>}
    />
    <Table.Column
        title={<Typography.Text ellipsis>结算方式</Typography.Text>}
        render={(e: OrderItem) => <InfoLine contentPlaceHolder='-'>
            {e.card ? `${e.card.name ?? e.card.number ?? '年卡'}兑换` : null}
        </InfoLine>}
    />
</Table>;

const DeliveryInfo = (props: {repo: Repository,
    order: Order,
    delivery: Delivery
}) => {
    const {delivery} = props;
    return <><Descriptions column={1} size='small' bordered>
        <Descriptions.Item label='物流单号'>
            <Typography.Link copyable>
                {delivery.externalNumber}
            </Typography.Link>
        </Descriptions.Item>
        {delivery.number && delivery.number.length > 0 ?
            <Descriptions.Item label='外部单号'>
                <Typography.Link copyable>{delivery.number}</Typography.Link>
            </Descriptions.Item>: null}
        <Descriptions.Item label='物流详情'>
            <Popover placement='right' trigger='click'
                     content={<DeliveryTrackInfo {...props}/>}>
                <Button type='link' disabled={!delivery.externalNumber}>点击查看</Button>
            </Popover>
        </Descriptions.Item>
    </Descriptions>
    </>;
};

const DeliveryTrackInfo = (props: {
    repo: Repository,
    order: Order,
    delivery: Delivery }) => {
    const {repo, order, delivery} = props;
    const [items, setItems] = useState<{time: string, context: string}[]>();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState<TrackResponse[]>();

    const loadItems = (number: string, phone?: string) => {
        setLoading(true);
        repo.deliveryTrackInfo({
            // number: delivery.externalNumber ?? delivery.number,
            // phone: delivery.phone
            number: number,
            phone: phone
        }).then(setItems)
            .catch(setError)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setError(undefined);
        setLoading(true);
        const deliverNo = delivery.externalNumber ?? delivery.number;
        repo.trackInfo(order.number)
            .then(g => {
                setGroups(g);
                loadItems(g.length ? g[0].logisticNo : deliverNo)
            })
            .catch(() => loadItems(deliverNo, delivery.phone));
    }, [delivery, order, repo]);

    /*
    groups?.length ? <>
     */
    const content = error || loading ? <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={error ?? <Spin/>}/>
        : items && items.length > 0 ? <Steps progressDot current={0} size='small' direction='vertical'>
            {items.map(v => <Steps.Step key={v.time} title={v.time} description={v.context}/>)}
        </Steps> : <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description='未获取到内容'/>;
    return groups?.length ? <Space direction='vertical'>
        <Segmented options={groups.map(v => v.logisticNo)}
                   onChange={v => loadItems(v.toString())}/>
        {content}
    </Space> : content;
}

const formatTime = (str?: string) => {
    if (!str) return undefined;
    const date = new Date(str);
    let format = 'HH:mm';
    const d = new Date();
    format = date.getFullYear() !== d.getFullYear() ? `YYYY/MM/DD ${format}`
        : date.getMonth() !== d.getMonth() || date.getDay() !== d.getDay() ? `MM/DD ${format}`
            : format;
    return moment(date).format(format);
}