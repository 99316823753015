import {
    Input,
    Button,
    Table,
    Typography,
    Radio,
    Space, List, Popover,
    DatePicker, Modal, Spin
} from "antd";
import {useUserSessionContext} from "@/repo/context";
import {useEffect, useState} from "react";
import {Order} from "@/repo/model/order";
import {SearchOutlined, LoadingOutlined, EyeInvisibleOutlined} from "@ant-design/icons";
import UserCell from "@/component/table/user-cell";
import {disableStyle, InfoLine, Label} from "@/component/table";
import moment from "dayjs";
import CommonTable from "@/component/table/common-table";
import {PageList, RequestPageInfo} from "@/repo/model/common";
import ConfirmView from './confirm';
import OrderItemView from './order-item';
import Cell from "@/component/table/cell";
import Repository from "@/repo/repository";
import ExportButton from "@/component/button/export-button";
import {Delivery} from "@/repo/model/delivery";
import {UserPhone} from "@/repo/model/user";

const orderFilters = ['已创建', '已审核', '已发货', '完成', '关闭', '售后'];

export default () => {
    const [datasource, setDatasource] = useState<PageList<Order>>();
    const [loading, setLoading] = useState(false);
    const {repo, hasPermission} = useUserSessionContext();
    const [search, setSearch] = useState<{
        cardSellerName?: string
        orderNo?: string,
        phone?: string,
        status?: number,
        startAt?: string,
        endAt?: string
    }>();
    const [requestPage, setRequestPage] = useState<RequestPageInfo>({offset: 0});
    const [editOrder, setEditOrder] = useState<Order>();

    const refresh = () => setRequestPage(p => ({...p, offset: 0}));

    useEffect(() => {
        if (repo) {
            setLoading(true);
            repo?.listOrder({
                page: requestPage,
                fillDelivery: true,
                fillCurrency: true,
                fillUser: true,
                ...search
            }).then(setDatasource)
                .catch(e => Modal.error({content: e}))
                .finally(() => setLoading(false));
        }
    }, [repo, requestPage]);
    return <>
        <CommonTable
            onPageChange={(page, pageSize) => setRequestPage({...requestPage, offset: (page - 1) * pageSize, size: pageSize})}
            rowKey='number'
            expandable={repo ? {
                expandedRowRender: t => <OrderItemView order={t} repo={repo} showLog showStatus/>,
            } : undefined}
            rightAction={<>
                <DatePicker.RangePicker onChange={v => {
                    let startAt: string|undefined = undefined;
                    let endAt: string|undefined = undefined;
                    if (v) {
                        const [s, e] = v;
                        startAt = s?.toISOString();
                        endAt = e?.toISOString();
                    }
                    setSearch(p => ({
                        ...p,
                        startAt: startAt,
                        endAt: endAt
                    }));
                    setRequestPage({offset: 0});
                }} />
                <Input placeholder='订单号'
                       allowClear
                       value={search?.orderNo}
                       onChange={e => setSearch(p => ({...p, orderNo:  e.target.value}))}/>
                <Input placeholder='开卡人'
                       allowClear
                       value={search?.cardSellerName}
                       onChange={e => setSearch(p => ({...p, cardSellerName:  e.target.value}))}/>
                <Input placeholder='买家手机号'
                       inputMode="tel"
                       allowClear
                       value={search?.phone}
                       onChange={e => setSearch(p => ({...p, phone:  e.target.value}))}/>
                <Button icon={<SearchOutlined/>} onClick={refresh}/>
                {repo && hasPermission('3') ? <ExportButton action={repo.orderExport}/> : null}
            </>}
            loading={loading}
            {...datasource}>
            <Table.Column
                title={<Typography.Text ellipsis>订单</Typography.Text>}
                render={(e: Order) => <Space size={0} direction='vertical'>
                    <InfoLine copyable>{e.name}</InfoLine>
                    <InfoLine label={`ID: ${e.number}`}/>
                    {e.createTime ? <Label>时间 {formatTime(e.createTime)}</Label> : null}
                </Space>}
            />
            <Table.Column
                title={<Typography.Text ellipsis>用户</Typography.Text>}
                render={(e: Order) => <Space>
                    <InfoLine contentPlaceHolder='无用户'>
                        {e.user ? <UserCell {...e.user} showPhone repo={repo}/> : null}
                    </InfoLine>
                    {hasPermission(['2','3','4']) && repo ? <PhoneCustomer order={e} repo={repo}/> : null}
                </Space>
                }
            />
            <Table.Column
                title={<Typography.Text ellipsis>顾问</Typography.Text>}
                render={(e: Order) => <InfoLine contentPlaceHolder='-'>
                    {e.card?.agentUser ? <UserCell {...e.card?.agentUser}
                                                   repo={repo}
                                                   showLogin={false}
                                                   showPhone/> : null}
                </InfoLine>}/>
            <Table.Column
                title={<Typography.Text ellipsis>年卡</Typography.Text>}
                render={(e: Order) => <InfoLine contentPlaceHolder='-'>
                    {e.card ? <Cell {...e.card}
                                    description={e.card.externalNumber}
                                    createTime={undefined}
                                    coverName={undefined}/> : null}
                </InfoLine>}/>
            {/*           <Table.Column
                align='right'
                title={<Typography.Text ellipsis>价格</Typography.Text>}
                render={(e: Order) => <Space direction='vertical' size={0}>
                    <InfoLine contentPlaceHolder='无价格'>
                        {e.value ? `${e.currency?.symbol}${e.value}` : null}
                    </InfoLine>
                    {e.payType ? <InfoLine label='支付方式'>{e.payType}</InfoLine> : null}
                    {e.payNumber ? <InfoLine label='支付号'>{e.payNumber}</InfoLine> : null}
                </Space>}
            />*/}
            <Table.Column
                title={<Typography.Text ellipsis>收货信息</Typography.Text>}
                render={(e: Order) => !repo || !e.deliveries || e.deliveries.length <= 0 ? <InfoLine contentPlaceHolder='无信息'/> : <Space direction='vertical' size={0}>
                    {e.deliveries.map((v, index) => <AddressCell
                        key={index}
                        deliver={v} repo={repo}/>)}
                </Space>}/>
            <Table.Column
                align='right'
                title={<Typography.Text ellipsis>{search?.status ? <div style={{color: 'green'}}>{orderFilters[search.status - 1]}</div> :  '状态'}</Typography.Text>}
                filterDropdown={p => {
                    const changeFilter = (v?: number) => {
                        if (v !== search?.status) {
                            setSearch({...search, status: v});
                            refresh();
                            p.confirm({closeDropdown: true});
                        }
                    }
                    return <List style={{padding: 10}}
                                 footer={<Button type='link'
                                                 size='small'
                                                 disabled={!search?.status}
                                                 onClick={() => changeFilter()}>清空</Button> }>
                        <Radio.Group onChange={v => changeFilter(v.target.value)} value={search?.status ?? 0}>
                            {orderFilters.map((v, index) => <List.Item key={index}>
                                <Radio value={index + 1}>{v}</Radio>
                            </List.Item> )}
                        </Radio.Group>
                    </List>;
                }}
                filtered={search?.status !== undefined}
                render={(e: Order) => <Space size={0} align="end" direction='vertical'>
                    <Typography.Text style={{color: !e.status ? 'red'
                            : e.status >= 126 ? 'green'
                                : e.status < 0 ? 'rgba(0,0,0,0.4)' : 'orange'}}>{
                        e.status === undefined ? '异常'
                            : e.status === -1 ? '已取消'
                                : e.status === -127 ? '已关闭'
                                    : e.status === 0 ? '已创建'
                                        : e.status === 1 ? '待审核'
                                            : e.status === 2 ? '已审核'
                                                : e.status === 3 ? '已发货'
                                                    : e.status === 4 ? '已签收'
                                                        : e.status >= 126 ? '已完成'
                                                            : <span style={disableStyle}>未知</span>}</Typography.Text>
                    {e.processStatus ? <Typography.Text style={{color: 'red'}}>
                        {e.processStatus === 1 ? '申请售后'
                            : e.processStatus === 2 ? '拒绝退货'
                                : e.processStatus === 3 ? '同意退货'
                                    : '售后中'}
                    </Typography.Text> : null}
                    {e.summary ? <Label>{e.summary}</Label> : null}
                </Space>}
            />
            <Table.Column
                align='right'
                title={<Typography.Text ellipsis>操作</Typography.Text>}
                render={(e: Order) => {
                    const status = e.status ?? 0;
                    const processStatus = status < 126 ? (e.processStatus ?? 0) : 0;
                    const button = processStatus === 1
                        ? (hasPermission(['3', '4']) ? <Button type='primary'
                                                               danger
                                                               size='small' onClick={() => setEditOrder(e)}>审核</Button> : null)
                        : status < 1
                            ? null : status === 1 ? hasPermission('2') ? <Button type='primary' size='small'
                                                                                 danger
                                                                                 onClick={() => setEditOrder(e)}>审核</Button> : null
                                : status === 2
                                    ? <Button type='primary' size='small' onClick={() => setEditOrder(e)}>发货</Button>
                                    : null;
                    return button ?? (hasPermission(['2','3','4']) && repo ? <InputSummary order={e} repo={repo}
                                                                                           onChange={o => {
                                                                                               if (datasource && o) {
                                                                                                   const index = datasource.items.findIndex(v => v.number === o.number);
                                                                                                   if (index >= 0) {
                                                                                                       const items = datasource.items;
                                                                                                       items[index] = o;
                                                                                                       setDatasource({...datasource, items: [...items]});
                                                                                                   }
                                                                                               }
                                                                                           }}/> : null);
                }}/>
        </CommonTable>
        <ConfirmView order={editOrder} onComplete={order => {
            setEditOrder(undefined);
            if (order && datasource) {
                const index = datasource.items.findIndex(v => v.number === order.number);
                let newItems;
                if (index >= 0) {
                    datasource.items[index] = order;
                    newItems = [...datasource.items];
                } else newItems = [order, ...datasource.items];
                setDatasource({...datasource, items: newItems});
            }
        }}/>
    </>
};

const AddressCell = (props: {deliver: Delivery, repo: Repository}) => {
    const {deliver, repo} = props;
    const {
        encrypt,
        name,
        phone,
        value,
        currency,
        addressLine2, address, addressLine1} = deliver;
    const [loading, setLoading] = useState(false);
    const [display, setDisplay] = useState<{
        name: string,
        phone: string;
        address: string;
    }>();
    return <div style={{maxWidth: 200}}>
        <InfoLine label='姓名'
                  action={!display && encrypt ? async () => {
                      setLoading(true);
                      const n = await repo.decrypt(encrypt.name);
                      const p = await repo.decrypt(encrypt.phone);
                      const a = await repo.decrypt(encrypt.address);
                      setDisplay({
                          name: n && n.length > 0 ? n : (name ?? ''),
                          phone: p && p.length > 0 ? p : (phone ?? ''),
                          address: a && a.length > 0 ? a : (address ?? '')
                      });
                      setLoading(false);
                  } : undefined}
                  actionTooltip='显示敏感数据'
                  actionIcon={!display && encrypt ? loading ? <LoadingOutlined /> : <EyeInvisibleOutlined/> : undefined}>{display?.name ?? name}</InfoLine>
        <InfoLine label='电话'>{display?.phone ?? phone}</InfoLine>
        <InfoLine label='地址' multipleLine>
            <Space size={0} direction='vertical' style={{fontSize: 12}}>
                {display?.address ?? address}
                <div style={{opacity: 0.6}}>{addressLine1 === addressLine2 ? addressLine1 : `${addressLine2} ${addressLine1}`}</div>
            </Space>
        </InfoLine>
        {value ? <InfoLine label='邮费'>{currency?.symbol}{value}</InfoLine> : null}
    </div>;
}

const PhoneCustomer = (props: {
    order: Order,
    repo: Repository}) => <Popover trigger='click'
                                    placement='bottomRight'
                                    destroyTooltipOnHide
                                    content={<PhonePanel {...props}/>}>
    <Typography.Link>联系</Typography.Link>
</Popover>;

const PhonePanel = (props: {
    order: Order,
    repo: Repository}) => {
    const {order, repo} = props;
    const [phone, setPhone] = useState<UserPhone>();
    useEffect(() => {
        setPhone(undefined);
        repo.getPhoneByOrder(order.number)
            .then(setPhone)
            .catch(e => Modal.error({content: e}));
    }, [order, repo]);
    return <Space direction='vertical'>
        <Typography.Text>订单 {order.number} 联系方式</Typography.Text>
        {phone ? <>
                <Typography.Text>使用手机 <Typography.Text strong type='danger'>{phone.phone}</Typography.Text> 拨打</Typography.Text>
                <Typography.Text type='danger' strong style={{fontSize: 24}}>{phone.secretPhone ?? phone.clientPhone}</Typography.Text>
        </> : <Spin size='large'/>}
    </Space>
}

const InputSummary = (props: {
    order: Order,
    repo: Repository,
    onChange: (order?: Order) => void}) => {
    const {order, repo, onChange} = props;
    const [inputText, setInputText] = useState<string>();
    const [open, setOpen] = useState(false);
    const [committing, setCommitting] = useState(false);
    useEffect(() => setInputText(order.summary), [order]);
    return <Popover trigger='click'
                    destroyTooltipOnHide
                    open={open}
                    placement='bottomRight'
                    content={<Space direction='vertical'>
                        <Typography.Text>{order.number} 备注</Typography.Text>
                        <Space>
                            <Label>备注信息</Label>
                            <Input.TextArea
                                autoFocus
                                value={inputText}
                                onChange={v => setInputText(v.target.value)}
                                placeholder='输入备注信息'/>
                        </Space>
                        <div style={{display: "flex", flexDirection: 'row-reverse'}}>
                            <Button type="primary" size='small'
                                    loading={committing}
                                    onClick={inputText ? () => {
                                        setCommitting(true);
                                        repo.updateOrderSummary(order.number, inputText)
                                            .then(() => setOpen(false))
                                            .then(() => onChange({...order, summary: inputText}))
                                            .finally(() => setCommitting(false));
                                    } : undefined}>确定</Button>
                            <Button size='small' style={{marginRight: 10}} onClick={() => setOpen(false)}>取消</Button>
                        </div>
                    </Space>}>
        <Typography.Link onClick={() => setOpen(true)}>备注</Typography.Link>
    </Popover>;
};

const formatTime = (str?: string) => {
    if (!str) return undefined;
    const date = new Date(str);
    return moment(date).format(`YYYY/MM/DD HH:MM`);
}
