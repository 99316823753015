import {Statistic} from "antd";
import {PageHeader} from "@ant-design/pro-components";

export default  () => {
    return <>
        <PageHeader>
            <Statistic title="总收益 (CNY)" value={0} precision={2} />
        </PageHeader>
    </>
};
