import {CSSProperties, ReactNode, useRef} from "react";
import {useSize} from "@/hook/layout";
import { TableProps} from "antd";
export default (props: {
    className?: string,
    style?: CSSProperties,
    builder: (params: TableProps<any>) => ReactNode}) => {
    const {className, style, builder} = props;
    const target = useRef<HTMLDivElement>(null);
    const size = useSize(target);
    return <div className={className}
                style={style}
                ref={target}>
        {size ? builder({
            style: {height: 0},
            scroll: {x: 'max-content', y:  size.height - 55},
            pagination: false,
            rowKey: 'id',
            rowClassName: (_, index) => index % 2 === 0 ? '' : 'rowBackground'
        }) : null}
    </div>;
};
