import {Avatar, Image, Select, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {useUserSessionContext} from "@/repo/context";
import {CSSProperties, useState} from "react";
import {User} from "@/repo/model/user";
import {Label} from "@/component/table";

export default (props: {
    user?: User,
    onPick: (user: User) => void,
    disabled?: boolean,
    onSearch?: (content: string) => void,
}) => {
    const {user, disabled, onPick, onSearch} = props;
    const {repo} = useUserSessionContext();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchContent, setSearchContent] = useState<string>();
    const [items, setItems] = useState<User[]>();
    return <div>
        <Select
            disabled={disabled}
            onBlur={() => setOpen(false)}
            value={null}
            filterOption={false}
            onSearch={v => {
                setSearchContent(v);
                onSearch ? onSearch(v) : undefined;
            }}
            onChange={v => {
                setOpen(false);
                const g = items?.find(e => e.id === v);
                if (g) onPick(g);
            }}
            suffixIcon={<SearchOutlined
                onClick={!loading && searchContent && repo ? () => {
                    setLoading(true);
                    repo.listUser({search: searchContent})
                        .then(v => setItems(v.items))
                        .then(() => setOpen(true))
                        .finally(() => setLoading(false));
                } : undefined} />}
            loading={loading}
            open={open}
            showSearch
            placeholder='输入用户手机 / 名称搜索'>
            {items?.map(v => <Select.Option
                value={v.id}
                key={v.id}>
                <UserItem user={v}/>
            </Select.Option>)}
        </Select>
        {user ? <UserItem user={user} style={{marginTop: 10}}/> : null}
    </div>
};

const UserItem = (props: {user: User, style?: CSSProperties}) => {
    const {user, style} = props;
    const displayName = user.name ?? user.login;
    return <Space align='center' size={4} style={style}>
        <Avatar
            size='small'
            icon={user.avatar ? <Image src={user.avatar}/> : undefined}>{
            user.avatar ? null : displayName?.substring(0, 1)}</Avatar>
        <Label>{user.phone}</Label>
        {displayName ? <Label>({displayName})</Label> : null}
    </Space>
};
