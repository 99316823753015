import {
    Input,
    Form,
    Upload,
    Divider,
    Space,
    Typography,
    Checkbox,
    Row,
    Col,
    Avatar, Modal
} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {User} from "@/repo/model/user";
import moment from "dayjs";
import ContentEditor from '@/component/dialog/content-editor';
import {responseTransfer} from "@/repo/response/transfer";
import {useState} from "react";
import Repository from "@/repo/repository";
import {passwordRegex} from "@/util/string-utils";

export const permissions = [['小程序登录', '1'], ['订单审核', '2'], ['后台运营', '3'], ['超级管理员', '4']];

export default (props: {
    user?: User,
    repo: Repository,
    editable: boolean,
    onComplete: (user?: User, removed?: boolean) => void
}) => {
    const {user, editable, repo, onComplete} = props;
    const userId = user?.id ?? '';
    const [avatar, setAvatar] = useState(user?.avatar);
    return <ContentEditor
        item={user}
        onInit={async item => {
            setAvatar(item.avatar);
            const e = item.encrypt;
            let login = item.login;
            let phone = item.phone;
            if (e) {
                const n = e.name ? await repo.decrypt(e.name) : login;
                const p = e.phone ? await repo.decrypt(e.phone) : phone;
                if (n?.length) login = n;
                if (p?.length) phone = p;
            }
            return ({
                ...item,
                phone: phone,
                login: login,
                birthday: item.birthday ? moment(new Date(item.birthday)) : undefined
            })
        }}
        onComplete={onComplete}
        idGetter={item => item.id}
        onCreateItem={repo ? item => repo.createUser({...item, avatar: avatar}) : undefined}
        onUpdateItem={repo ? item => editable ? repo.updateUser(userId, {...item, avatar: avatar}, r => {
                throw r;
        }).catch(e => {
            if (e.code === 4) return new Promise(r => Modal.confirm({
                content: '手机已注册用户，是否合并？',
                onOk: () => repo.mergeUser(userId, item.phone ?? '')
                    .then(v => {
                        Modal.info({content: '已合并至指定手机用户'});
                        onComplete(user, true);
                    })
                    .catch(Modal.error)
            }));
            throw e.message;
        }): repo.updateUserPhone(userId, item.phone ?? '') : undefined}
        titleBuilder={isNew => isNew ? '新增用户' : <Space direction="vertical" size={0}>
            编辑用户
            <Typography.Text
                type="secondary"
                style={{fontSize: 10}}
                copyable={{tooltips: '复制用户ID'}}>{userId}</Typography.Text>
        </Space>}
        contentBuilder={isNew => <>
            <Form.Item name="phone" label="手机号" required={isNew}
                       rules={[{required: isNew}]}>
                <Input style={{width: '100%'}}
                       inputMode="tel"
                       placeholder='输入手机号'/>
            </Form.Item>
            {/*  <Form.Item name="email" label="邮箱" rules={[
                {type: 'email', message: '邮件格式不正确'}
            ]}>
                <Input style={{width: '100%'}} placeholder='输入邮箱'/>
            </Form.Item>
            <Form.Item name="realName" label="姓名" rules={[
                {whitespace: true}
            ]}><Input placeholder='输入姓名' />
            </Form.Item>
            <Form.Item name="gender" label="性别" initialValue={0}>
                <Radio.Group>
                    <Radio value={0}>未知</Radio>
                    <Radio value={1}>男</Radio>
                    <Radio value={2}>女</Radio>
                    <Radio value={3}>其他</Radio>
                </Radio.Group>
            </Form.Item>
                        <Form.Item name="birthday" label="生日">
                <DatePicker style={{width: '100%'}}
                            placeholder='选择生日' />
            </Form.Item>
                             */}
            <Form.Item name="name" label="昵称">
                <Input placeholder='从微信小程序登录后可自动获取' disabled={!editable} />
            </Form.Item>
            <Form.Item label="头像">
                {repo ? <Upload action={repo.getUploadImagePath()}
                                headers={repo.getCommonHeaders()}
                                disabled={!editable}
                                accept='image/*'
                                data={{type: 1}}
                                onChange={e => {
                                    const {file, fileList} = e;
                                    if (file.response && fileList && fileList.length > 0) responseTransfer(file.response,setAvatar);
                                }}
                                onRemove={() => setAvatar(undefined)}
                                maxCount={1}
                                listType="picture">
                    <Avatar size={80}
                            src={avatar ? <img src={avatar}/> : undefined}>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                    </Avatar>
                </Upload>: null}
            </Form.Item>
            {editable ? <>
            <Divider/>
            <Form.Item name="login" label="用户名" required rules={[{required: true}]}>
                <Input placeholder='输入登录名，用于登录系统后台'/>
            </Form.Item>
            <Form.Item name="password" label="登录密码" rules={[{
                pattern: passwordRegex,
                message: '密码至少8-16个字符、1个大写字母，1个小写字母和1个数字'}]}>
                <Input.Password placeholder='输入登录密码，用于登录系统后台' />
            </Form.Item>
            <Form.Item name="permissions" required label="权限" rules={[{
                required: true,
                message: '勾选至少一个权限'
            }]}>
                <Checkbox.Group>
                    <Row wrap gutter={[2, 4]}>
                        {permissions.map((p, i) => <Col key={p[1]}>
                            <Checkbox value={p[1]}>{p[0]}</Checkbox>
                        </Col>)}
                    </Row>
                </Checkbox.Group>
            </Form.Item>
            <Form.Item name="summary" label="备注">
                <TextArea placeholder='输入备注'/>
            </Form.Item>
            </> : null}
        </>}>
    </ContentEditor>;
}
