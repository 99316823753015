import {ReactNode} from "react";
import {Pagination, Table} from "antd";
import TableWrapper from "@/component/table/table-wrapper";
import { ExpandableConfig } from "antd/lib/table/interface";
import {PageHeader} from "@ant-design/pro-components";

function CommonTable<T>(props: {
    leftAction?: ReactNode,
    rightAction?: ReactNode,
    children?: ReactNode,
    loading?: boolean,
    expandable?: ExpandableConfig<T>;
    rowKey?: string,
    offset?: number,
    count?: number,
    size?: number,
    items?: T[],
    onPageChange?: (page: number, pageSize: number) => void;
}) {
    const {
        leftAction,
        rightAction,
        children,
        items,
        rowKey,
        offset,
        count,
        size,
        onPageChange,
        expandable,
        loading} = props;
    return <>
        <PageHeader
            title={leftAction}
            extra={rightAction}/>
        <TableWrapper
            style={{flex: 1}}
            builder={param => <Table {...param}
                                     expandable={expandable}
                                     dataSource={items}
                                     rowKey={rowKey ?? param.rowKey ?? 'id'}
                                     loading={loading}>
                {children}
            </Table>} />
        {size && count && count / size > 1 ? <Pagination style={{alignSelf: 'flex-end', margin: 10}}
                                                         showSizeChanger={false}
                                                         onChange={onPageChange}
                            total={count}
                            current={offset != undefined ? Math.floor(offset / size) + 1: undefined}
                            pageSize={size}/> : null}
    </>
}

export default CommonTable;
