import {
    Input,
    Button,
    Table,
    Typography,
    Space,
    Popover,
    List,
    Avatar
} from "antd";
import {useEffect, useState} from "react";
import UserInput, {permissions} from './input';
import {useUserSessionContext} from "@/repo/context";
import {User} from "@/repo/model/user";
import {disableStyle, InfoLine, Label} from "@/component/table";
import UserCell from "@/component/table/user-cell";
import {SearchOutlined, ProfileOutlined, LoadingOutlined, EyeInvisibleOutlined} from "@ant-design/icons";
import CommonTable from "@/component/table/common-table";
import {PageList, RequestPageInfo} from "@/repo/model/common";
import Repository from "@/repo/repository";
import {Card} from "@/repo/model/card";
import CardEditView from "@/view/card/input";
import styles from './index.module.css';
import moment from "dayjs";

export default () => {
    const [editUser, setEditUser] = useState<User>();
    const [datasource, setDatasource] = useState<PageList<User>>();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState<{
        cardSellerName?: string
        orderNo?: string,
        phone?: string,
        weixinName?: string
    }>();

    const {repo, hasPermission} = useUserSessionContext();
    const [requestPage, setRequestPage] = useState<RequestPageInfo>({offset: 0});
    const [cardOpenUserId, setCardOpenUserId] = useState<string>();
    useEffect(() => {
        if (repo) {
            setLoading(true);
            repo.listUser({
                page: requestPage,
                fillPermissionCount: true,
                fillCardCount: true,
                ...search})
                .then(setDatasource)
                .finally(() => setLoading(false));
        }
    }, [repo, requestPage]);
    const editPermission = hasPermission('4');
    return <>
        <CommonTable
            {...datasource}
            onPageChange={(page, pageSize) => setRequestPage({...requestPage, offset: (page - 1) * pageSize, size: pageSize})}
            leftAction={editPermission ? <Button type='primary' onClick={() => setEditUser({id:''})}>新用户</Button> : null}
            rightAction={<>
                <Input placeholder='手机号'
                       inputMode="tel"
                       allowClear
                       value={search?.phone}
                       onChange={e => setSearch(p => ({...p, phone:  e.target.value}))}/>
                <Input placeholder='昵称'
                       allowClear
                       value={search?.weixinName}
                       onChange={e => setSearch(p => ({...p, weixinName:  e.target.value}))}/>
                <Input placeholder='订单号'
                       allowClear
                       value={search?.orderNo}
                       onChange={e => setSearch(p => ({...p, orderNo:  e.target.value}))}/>
                <Input placeholder='开卡人'
                       allowClear
                       value={search?.cardSellerName}
                       onChange={e => setSearch(p => ({...p, cardSellerName:  e.target.value}))}/>
                <Button icon={<SearchOutlined onClick={() => setRequestPage({...requestPage, offset: 0})} />}/>
            </>}
            loading={loading}>
            <Table.Column
                title={<Typography.Text ellipsis>用户</Typography.Text>}
                render={(e: User) => <UserCell {...e} showId />}/>
            <Table.Column
                title={<Typography.Text ellipsis>账号</Typography.Text>}
                render={(e: User) => <UserInfo user={e} repo={repo}/>}
            />
            {/*
            <Table.Column
                title={<Typography.Text ellipsis>信息</Typography.Text>}
                render={(e: User) => <div className='column'>
                    <InfoLine label="性别" contentPlaceHolder='未设置'>
                        {e.gender === 0 ? null
                            : e.gender === 1 ? '男'
                                : e.gender === 2 ? '女'
                                    : '其他'}

                    </InfoLine>
                    <InfoLine label="生日" contentPlaceHolder='未设置'>
                        {e.birthday}
                    </InfoLine>
                    <InfoLine label="区域" contentPlaceHolder='未设置'>
                        {e.country || e.province || e.city
                            ? `${e.country ?? ''}${e.province ?? ''}${e.city ?? ''}` : undefined}
                    </InfoLine>
                </div>}
            />*/}
            <Table.Column
                title={<Typography.Text ellipsis>年卡</Typography.Text>}
                render={(e: User) => <InfoLine
                    actionIcon={<CardAction onClick={o => setCardOpenUserId(o ? e.id : undefined)}
                                            editable={editPermission}
                                            user={e}
                                            repo={cardOpenUserId === e.id ? repo : undefined}/>}
                    action={() => {}}
                    actionTooltip='年卡详情'
                    contentPlaceHolder="未分配">
                    {(e.cardCount ?? 0 > 0) ? e.cardCount : null}
                </InfoLine>}
            />
            <Table.Column
                title={<Typography.Text ellipsis>权限</Typography.Text>}
                render={(e: User) => {
                    const p = e.permissions;
                    return <InfoLine contentPlaceHolder="未分配">{
                        p && p.length > 0 ? <Space direction='vertical' size={0}>
                            {permissions
                                .filter(v => p.indexOf(v[1]) >= 0)
                                .map(v => <Label key={v[1]}>{v[0]}</Label>)}
                        </Space> : undefined
                    }</InfoLine>;}
                }
            />
            <Table.Column
                align='right'
                title={<Typography.Text ellipsis>状态</Typography.Text>}
                render={(e: User) =>  <div className='column' style={{alignItems: 'flex-end'}}>
                    <Typography.Text style={{color: 'green'}}>{
                        e.status === undefined || e.status < 0 ? <span style={{color: 'red'}}>禁用</span>
                            : e.status === 0 ? '正常'
                                : e.status > 0 ? '确认'
                                    : <span style={disableStyle}>未知</span>
                    }</Typography.Text>
                    {e.summary ? <Label>{e.summary}</Label> : null}
                </div>}
            />
            {hasPermission(['3', '4']) ? <Table.Column
                align='right'
                title={<Typography.Text ellipsis>操作</Typography.Text>}
                render={(e: User) => <Button
                        type='primary'
                        onClick={() => setEditUser(e)}
                        size='small'>编辑</Button>}
            /> : null}
        </CommonTable>
        {repo ? <UserInput user={editUser}
                           repo={repo}
                           editable={editPermission ?? false}
                           onComplete={(user , removed) =>  {
            setEditUser(undefined);
            if (user && datasource) {
                let newItems;
                if (removed) newItems = datasource.items.filter(v => v.id !== user.id); else {
                    const index = datasource.items.findIndex(v => v.id === user.id);
                    if (index >= 0) {
                        datasource.items[index] = user;
                        newItems = [...datasource.items];
                    } else newItems = [user, ...datasource.items];
                }
                setDatasource({...datasource, items: newItems});
            }
        }}/> : null}
    </>
};

const CardAction = (props: {
    onClick: (open: boolean) => void,
    repo?: Repository,
    editable?: boolean,
    user: User}) => {
    const {repo, user, editable, onClick} = props;
    const [cards, setCards] = useState<Card[]>();
    const [editCard, setEditCard] = useState<Card>();
    useEffect(() => {
        if (repo) {
            repo.listUserCard(user.id).then(v => setCards(v.items));
        } else setCards(undefined);
    }, [repo, user]);
    // onConfirm={() => setEditCard({id: '', user: user})}
    return <><Popover
        placement="right"
        title={editable ? <Button size="small"
                       onClick={() => {
                           setEditCard({id: '', user: user});
                           onClick(false);
                       }}>新年卡</Button> : null}
        content={cards && cards.length > 0 ? <List
                loading={!cards}
                size={'small'} bordered>
                {cards.map((v, index) => <List.Item key={v.id}
                                                    className={styles.cardItem}
                                                    onClick={editable ? () => {
                                                        setEditCard(v);
                                                        onClick(false);
                                                    } : undefined}>
                        <List.Item.Meta
                            avatar={
                                <Space>
                                    <Avatar size="large" shape='square' style={{marginRight: 4}} src={v.icon ?? v.cover}/>
                                    <Space direction='vertical' size={0}>
                                        {v.name ?? `未命名年卡(${v.id})`}
                                        <Label>{formatTime(v.createTime)}</Label>
                                    </Space>
                                </Space>
                            }/>
                    </List.Item>
                )}
            </List>
            : '未开通'}
        // title="已开通年卡"
        trigger="click"
        open={repo !== undefined}
        onOpenChange={onClick}>
        <ProfileOutlined />
    </Popover>
        <CardEditView card={editCard} onComplete={() => {
            setEditCard(undefined);
        }}/>
    </>
}

const UserInfo = (props: {user: User, repo?: Repository}) => {
    const {user, repo} = props;
    const [display, setDisplay] = useState<{
        name?: string;
        phone?: string;
        loading?: boolean;
        encrypt?: {name?: string, phone?: string}
    }>({});
    useEffect(() => setDisplay({
        name: user.login === user.id ? undefined : user.login,
        phone: user.phone,
        encrypt: user.encrypt
    }), [user]);
    const {name, phone, encrypt} = display;
    return <div className='column'>
        <InfoLine label="用户"
                  action={encrypt && repo ? async () => {
                      setDisplay(p => ({...p, loading: true}));
                      const name = encrypt?.name ? await repo?.decrypt(encrypt.name) : undefined;
                      const phone = encrypt?.phone ? await repo?.decrypt(encrypt.phone) : undefined;
                      setDisplay(p => ({
                          name: !name?.length ? p.name : name,
                          phone: !phone?.length ? p.phone : phone,
                          loading: false
                      }));
                  } : undefined}
                  actionTooltip='显示敏感数据'
                  actionIcon={encrypt ? display.loading ? <LoadingOutlined /> : <EyeInvisibleOutlined/> : undefined}
                  contentPlaceHolder='未设置'>{name}</InfoLine>
        <InfoLine label="手机" contentPlaceHolder='未绑定'>{phone}</InfoLine>
    </div>
}

const formatTime = (str?: string) => {
    if (!str) return undefined;
    const date = new Date(str);
    const d = new Date();
    const format = date.getFullYear() !== d.getFullYear() ? `YYYY/MM/DD` : `MM/DD`;
    return moment(date).format(`${format} HH:mm`);
}