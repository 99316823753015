export interface Currency {
    code: string;
    name?: string;
    icon?: string;
    symbol?: string;
    unit?: string;
    type?: string;
    description?: string;
    precision?: number;
    scale?: number;
    address?: string;
    chain?: string;
    status?: number;
    createTime?: string;
    modifyTime?: string;
}

export const defaultCurrency: Currency = {
    code: 'CNY',
    name: '人民币',
    unit: '元',
    symbol: '¥',
    scale: 2,
};
